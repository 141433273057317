import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation: ${fadeIn} 1s forwards;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  section {
    justify-content: space-between;
  }
  img {
    object-fit: contain;
    margin: 0px 0px;
  }

  h1 {
    align-self: center;
    color: ${({ theme }) => theme.blackText};
  }
`;
