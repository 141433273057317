import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as MyClientActions from '../../../store/modules/myclient/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import apiPayment from '../../../services/apiPayment'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Item from "./item";

const MySwal = withReactContent(Swal)

export default function Transaction() {
  const [rightMenu, setRightMenu] = useState(false)
  const [rightMenuInfo, setRightMenuInfo] = useState([])
  const [selectType, setSelectType] = useState('account')

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, page, pages, per_page, total } = useSelector(
    (state) => state.myclient,
  )

  const { balance, balance_block } = useSelector((state) => state.account)
  const { transfer } = useSelector((state) => state.transfer)
  const { deposit } = useSelector((state) => state.deposit)
  const account = useSelector((state) => state.account.account)
  const company = useSelector((state) => state.user.company)
  const [transferItens, settransferItens] = useState([])
  const [companySelected, setCompanySelected] = useState({})

  useEffect(() => {
    dispatch(MyClientActions.MyClientsRequest({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(
      MyClientActions.MyClientsRequest({ per_page: per_page, page: value }),
    )
  })

  const renderView = (type) => {
    return renderTransfer()
  }
  const renderTransfer = () => (
    <>
      <HeaderRigthModal>
        {' '}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false)
          }}
        />{' '}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="deposit">Cliente</LabelType>
        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatDate(companySelected.createdAt)}{' '}
        </ContentRightMenu>
        <LabelRightMenu> Nome: </LabelRightMenu>
        <ContentRightMenu> {companySelected.name} </ContentRightMenu>

        <LabelRightMenu> Email: </LabelRightMenu>
        <ContentRightMenu> {companySelected.email}</ContentRightMenu>
        <LabelRightMenu> Conta</LabelRightMenu>
        <ContentRightMenu> {companySelected.account} </ContentRightMenu>
        <LabelRightMenu> Agência</LabelRightMenu>
        <ContentRightMenu> {companySelected.branch} </ContentRightMenu>
        <LabelRightMenu> Chave pix: </LabelRightMenu>
        <ContentRightMenu> {companySelected.pix_key} </ContentRightMenu>
        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {companySelected.bank_name} - {companySelected.brank_number}{' '}
        </ContentRightMenu>
        <LabelRightMenu> Saldo: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatToReal(parseFloat(companySelected.balance))}{' '}
        </ContentRightMenu>
        {companySelected.step_register == 0 && (
          <ContentRightMenu>
            <ButtonDefault
              type="button"
              title="Aprovar"
              background={theme.color.green}
              color={theme.color.white}
              width="250px"
              height="50px"
              onClick={() =>handleApprove (companySelected)}
            />
          </ContentRightMenu>
        )}

        <ContentRightMenu>
          <ButtonDefault
            type="button"
            title="mais detalhes"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={() => handleDetailPress(companySelected)}
          />
        </ContentRightMenu>
      </RigthModal>
    </>
  )
  const handleNewPress = () => {
    history.push('/crm/cliente/new')
  }

  const handleApprove = (partner) => {
    MySwal.fire({
      title: `<p>Deseja aprovar a conta ${partner.name}?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Aprovar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return apiPayment
        .get(`/manager/v1/partner/approve/${partner.id}`)
          .then((response) => {
            setRightMenu(false)
            dispatch(MyClientActions.MyClientsRequest({ per_page: per_page, page: 1 }))
            return response.json()
          })
          .catch((error) => {})
      }
    })
  }

  const handleDetailPress = (myclient) => {
    dispatch(MyClientActions.MyClientDetail({ myclient }))

    history.push(`/crm/cliente/detalhe/${myclient.id}`)
  }

  const handleInfoRightMenu = (info) => {
    setSelectType('account')
    setRightMenu(true)
    setRightMenuInfo(info)
    setCompanySelected(info)
    apiPayment
      .get(`/payment/manager/v1/web/balance/${info.id}/partner`)
      .then(({ data }) => {
        setCompanySelected({ ...info, ...data })
      })
    // history.push("/transferencia/novo");
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Clientes</h1>
        <div className="horizontal">
          <p>Lista de sub-contas</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewPress}
          />
        </div>

        <Content>
          <div className="table">
            {!data.length && <p>nenhuma transferência realizada</p>}
            {!!data.length && (
              <>
                {data.map((item, index) => (
                  <Item item={item} index={index} handleInfoRightMenu={handleInfoRightMenu}/>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
        </Content>
      </Container>

      <ContainerRigthModal className={rightMenu ? 'show-right-menu' : ''}>
        {renderView(selectType)}
      </ContainerRigthModal>
    </ContainerItens>
  )
}
