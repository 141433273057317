import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiCustomer from '../../../services/apiCustomer'
import GetErrors from '../../../services/errors'
import * as MyClientActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'
import apiPayment from "../../../services/apiPayment";

export function* MyClientsRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { token } = yield select((state) => state.user)
    const { data } = yield call(apiCustomer.post, '/manager/v1/partners', {
      per_page,
      page,
    })



    yield put(MyClientActions.MyClientsRequestSuccess(data))



  } catch (error) {
    let code = '0'
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code
    }
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newMyClient(action) {
  try {
    const { callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    yield call(apiCustomer.post, '/manager/v1/partner', action.payload)
    yield put(MyClientActions.MyClientsRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback()
    }
  } catch (error) {
    let code = '0'
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code
    }
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* billingResumeRequest(action) {
  try {
    const { my_client_id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { token } = yield select((state) => state.user)
    const { data } = yield call(
      apiCustomer.get,
      `/billing/resume/${my_client_id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    console.log(data)
    yield put(MyClientActions.billingResumeSuccess({ totalResume: data }))
  } catch (error) {
    let code = '0'
    if (error.response && error.response.data && error.response.data.code) {
      code = error.response.data.code
    }
    const errorMessage = GetErrors('PT_BR', code)
    yield put(GeneralActions.checkErrorAndResolve({ error: errorMessage }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}



export function* MyClientGetDetail(action) {
  try {
    const { callback, id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiCustomer.get, `/manager/v1/partner/${id}`)

    const balanceRef = yield call(
      apiCustomer.get,
      `/payment/manager/v1/web/balance/${id}/partner`,
    )
    yield put(
      MyClientActions.MyClientDetail({
        myclient: { ...data, ...balanceRef.data },
      }),
    )
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}


export default all([
  takeLatest('@myclient/LIST_REQUEST', MyClientsRequest),
  takeLatest('@myclient/NEW', newMyClient),
  takeLatest('@myclient/GET_DETAIL', MyClientGetDetail),
  takeLatest('@myclient/RESUME_BILLING', billingResumeRequest),
])
