import React, { useEffect, useCallback, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import {
    ContainerItens,
    Container,
    TransactionItem,
    Content,
    DateLabel,
    Filter,
    ContainerButtons,
} from './styles'
import {
    formatToReal,
    formatDate,
    formatCnpj,
    formatHour,
} from '../../../utils/format'
import * as TransactionActions from '../../../store/modules/transaction/actions'
import Pagination from '@material-ui/lab/Pagination'
import * as TransferActions from '../../../store/modules/transfer/actions'
import * as DepositActions from '../../../store/modules/deposit/actions'
import * as PayBillActions from '../../../store/modules/paybill/actions'
import ToggleButton from 'react-bootstrap/ToggleButton';

import {
    PDFDownloadLink,
} from '@react-pdf/renderer'
import '../../../assets/animations/animations.css'

import { registerLocale } from "react-datepicker";

import ptBR from 'date-fns/locale/pt-BR';


import * as AccountActions from '../../../store/modules/account/actions'

import DepositModal from "./DepositModal";
import TransferModal from "./TransferModal";
import { ButtonDefault } from "../../../components/Buttons";
import ExtractPdf from "./ExtractPdf";
import PayBillModal from "./PayBillModal";
const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-tz')


registerLocale('pt-br', ptBR)
export default function TransactionByPartner({ client_id, client_type }) {
    const [transaction, setTransaction] = useState([])
    const [radioValue, setRadioValue] = useState('30');

    const radios = [
        { name: '30 dias', value: '30' },
        { name: '90 dias', value: '90' },
        { name: '120 dias', value: '120' },
    ];




    const [query, setQuery] = useState({
        from: rmDays(new Date(), 30).setUTCHours(0, 0, 0),
        to: addDays(new Date(), 1).setUTCHours(23, 59, 59), client_id, client_type
    })

    const theme = useTheme()
    const dispatch = useDispatch()
    let { data, page, pages } = useSelector(
        (state) => state.transaction,
    )
    const { balance, balance_block, account } = useSelector((state) => state.account)


    const [refPrint, setRefPrint] = useState(false);

    const [openDeposit, setOpenDeposit] = useState(false)

    const [openTransfer, setOpenTransfer] = useState(false)

    const [openPayBill, setOpenPayBill] = useState(false)

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function rmDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function setDate(from, to) {
        from = new Date(from)
        to = new Date(to)
        setQuery({
            ...query,
            from: from,
            to: to,
        })
    }


    useEffect(() => {
        dispatch(
            AccountActions.balanceRequest({ client_id, client_type: "partner" }),
        )

        queryTransactions()
        // );
    }, [dispatch])


    const queryTransactions = () => {
        setRefPrint(false)
        query.from = zonedTimeToUtc(new Date(query.from).setHours(1, 43, 0), 'Europe/Berlin')
        query.to = zonedTimeToUtc(new Date(query.to).setHours(24, 59, 0), 'Europe/Berlin')
        dispatch(
            TransactionActions.transactionsRequest({
                per_page: 10,
                page: 1,
                query: { ...query, client_id, client_type },
            }),
        )
    }


    const printTransctions = () => {
        setRefPrint(false)
        query.from = zonedTimeToUtc(new Date(query.from).setHours(1, 43, 0), 'Europe/Berlin')
        query.to = zonedTimeToUtc(new Date(query.to).setHours(24, 59, 0), 'Europe/Berlin')
        dispatch(
            TransactionActions.transactionsRequest({
                per_page: 100000000,
                page: 1,
                query: { ...query, client_id, client_type },
                order_by: { field: "created_at", direction: "asc" },
                callback: () => {
                    setRefPrint(true)
                }
            }),
        )

    }


    useEffect(() => {
        if (data.length > 0) {
            let dataFormated = []
            data.forEach((item) => {
                let checktIndex = dataFormated.findIndex(
                    (el) => el.key == formatDate(item.createdAt),
                )
                if (checktIndex > -1) {
                    dataFormated[checktIndex].data.push(item)
                } else {
                    dataFormated.push({ key: formatDate(item.createdAt), data: [item] })
                }
            })
            dataFormated.forEach((item, key) => {
                item.balance = item.data[item.data.length - 1].balance
            })

            setTransaction(dataFormated)
        }
    }, [data])

    const handlePagePress = useCallback((event, value) => {
        dispatch(
            TransactionActions.transactionsRequest({
                per_page: 5,
                page: value,
                query: { ...query, client_id, client_type },
            }),
        )
    })


    const typeTransactionsRender = (type, operation_type) => {
        switch (type) {
            case "pix":
            case 'refund_pix':
                return 'devolução de pix'
            case "ted":
            case 'refund_ted':
                return 'devolução de ted'
            case "deposit":
                switch (operation_type) {
                    case 'pix':
                        return 'Depósito pix'
                    case 'ted':
                        return 'Depósito ted'
                    case 'refund_ted':
                        return 'devolução de ted'
                    case 'refund_pix':
                        return 'devolução de pix'
                    case 'withdraw':
                        return 'retirada da sub-conta'
                    case 'internal':
                        return 'Depósito da sub-conta'
                }
            case "transfer":
                switch (operation_type) {
                    case 'pix':
                        return 'Transferência pix'
                    case 'ted':
                        return 'Transferência ted'
                    case 'internal':
                        return 'Transferência sub-conta'
                    case "ted-internal":
                        return 'Transferência ted interna'
                }
            case 'buy':
                return 'Compra de Stablecoin'
            case 'sell':
                return 'Venda de Stablecoin'
            case 'cancel':
                return 'Cancelamento de compra'
            case 'paybill':
                return 'Pagamento de conta'
            case "buy-fee-trader":
                return "Ganhos na venda"
        }
    }

    const changeRadioDays = (value) => {
        setRadioValue(value)
        setQuery({ ...query, from: rmDays(new Date(), parseInt(value)), to: addDays(new Date(), 1) })
    }


    const handleInfoRightMenu = (info) => {
        switch (info.operation_type) {
            case 'transfer':
                dispatch(
                    TransferActions.transferGet({
                        id: info.liquid_id,
                    }),
                )
                setOpenTransfer(true)
                break
            case 'deposit':
                dispatch(
                    DepositActions.depositGet({
                        id: info.liquid_id,
                    }),
                )
                setOpenDeposit(true)
                break
            case 'paybill':
                dispatch(
                    PayBillActions.Get({
                        id: info.liquid_id,
                    }),
                )
                setOpenPayBill(true)
                break
        }
    }


    return (
        <ContainerItens>
            <Container>
                <h1>Extrato</h1>
                <div className="horizontal">
                    <p>Todas as movimentações</p>
                </div>

                <Filter>
                    <h2>Filtros</h2>
                    <div className="filter_container">
                        <div className="filter_item">
                            <div>Data inicio:</div>
                            <DatePicker
                                max
                                dateFormat="dd/MM/yyyy"
                                locale="pt-br"
                                onChange={(date) => setDate(date, query.to)}
                                selected={query.from}
                            />
                        </div>
                        <div className="filter_item">
                            <div>Data fim:</div>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt-br"
                                onChange={(date) => setDate(query.from, date)}
                                selected={query.to}
                            />
                        </div>
                        <div className="filter_item">
                            <ButtonDefault
                                type="button"
                                title="filtrar"
                                background={theme.color.blackButton}
                                color={theme.color.white}
                                width="250px"
                                height="50px"
                                onClick={() => queryTransactions()}
                            />
                        </div>
                    </div>
                    <div className="rang-date">
                        {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant={radioValue === radio.value ? 'outline-success' : 'outline-success'}
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => changeRadioDays(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </div>
                </Filter>
                <Content>
                    <div className="table">
                        {!transaction.length && <p>nenhuma transferência realizada</p>}
                        {!!transaction.length && (
                            <>
                                {transaction.map((item, index) => (
                                    <>
                                        <DateLabel key={index}>{item.key}</DateLabel>
                                        {item.data.map((b, indexData) => (
                                            <TransactionItem
                                                key={indexData}
                                                onClick={() => {
                                                    handleInfoRightMenu(b)
                                                }}
                                            >
                                                <div className="bar-container">
                                                    <div
                                                        className="bar"
                                                        style={{
                                                            backgroundColor:
                                                                theme.color[b.operation_direct] || '#fff',
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="info">
                                                    <span className="title">
                                                        {typeTransactionsRender(
                                                            b.operation_type,
                                                            b.operation_service,
                                                        )}
                                                    </span>
                                                    <span className="subtitle">
                                                        {' '}
                                                        {b.subtitle == '' ? b.category_name : b.subtitle}
                                                    </span>
                                                </div>
                                                <div className={`price`}>
                                                    {' '}
                                                    <span className={`amount ${b.operation_direct}`}>
                                                        {formatToReal(parseFloat(b.amount))}
                                                    </span>
                                                    <span className="clock">
                                                        {' '}{formatHour(b.updatedAt)}{' '}
                                                    </span>
                                                </div>
                                                <div className="action">
                                                    <i className="material-icons icon">navigate_next</i>
                                                </div>
                                            </TransactionItem>
                                        ))}
                                    </>
                                ))}
                                <Pagination
                                    count={pages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={page}
                                    onChange={handlePagePress}
                                />
                            </>
                        )}
                    </div>
                    <div className="balance">
                        <div className="content">
                            <div className="item">
                                <div className="title">Saldo atual:</div>
                                <div className="subtitle">{formatToReal(balance)}</div>
                            </div>
                            <div className="item">
                                <div className="title">Saldo bloqueado:</div>
                                <div className="subtitle">{formatToReal(balance_block)}</div>
                            </div>
                        </div>


                        <ButtonDefault
                            type="button"
                            title="Gerar extrato"
                            background={theme.color.blackButton}
                            color={theme.color.white}
                            width="250px"
                            height="50px"
                            onClick={() => printTransctions()}
                        />


                        <ContainerButtons>

                            {refPrint && (
                                <PDFDownloadLink
                                    document={<ExtractPdf transactions={transaction} account={account} balance={balance} from={query.from} to={query.to} />}
                                    fileName={`extract_${account.number}_${account.name}.pdf`}
                                    className="button-pdf"
                                >
                                    {({ blob, url, loading, error }) =>
                                        loading ? (
                                            'Loading document...'
                                        ) : (
                                            <>Baixar</>
                                        )
                                    }
                                </PDFDownloadLink>
                            )}

                        </ContainerButtons>

                    </div>
                </Content>
            </Container>
            <DepositModal visible={openDeposit} onClose={() => setOpenDeposit(false)} />
            <TransferModal visible={openTransfer} onClose={() => setOpenTransfer(false)} />
            <PayBillModal visible={openPayBill} onClose={() => setOpenPayBill(false)} />
        </ContainerItens>
    )
}
