import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiPayment from '../../../services/apiPayment'
import GetErrors from '../../../services/errors'
import * as CompanyActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'

export function* CompanyRequest(action) {
  try {
    const { per_page, page,query } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiPayment.post, '/manager/v1/companies', {
      per_page,
      page,
      query
    })
    yield put(CompanyActions.CompanyRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newCompany(action) {
  try {
    const { callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/partner/v1/company',
      action.payload,
    )
    yield put(CompanyActions.CompanyRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* CompanyGetDetail(action) {
  try {
    const { callback, id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(apiPayment.get, `/manager/v1/company/${id}`)

    const balanceRef = yield call(
      apiPayment.get,
      `/payment/manager/v1/web/balance/${id}/customer`,
    )
    yield put(
      CompanyActions.CompanyDetail({
        company: { ...data, ...balanceRef.data },
      }),
    )
    if (callback) {
      callback(data)
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* CompanyTransactionsRequest(action) {
  try {
    const { per_page, page, query } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/manager/v1/web/transactions',
      {
        per_page,
        page,
        query,
      },
    )
    yield put(CompanyActions.CompanyTransactionsRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export default all([
  takeLatest('@company/LIST_REQUEST', CompanyRequest),
  takeLatest('@company/GET_DETAIL', CompanyGetDetail),
  takeLatest('@company/NEW', newCompany),
  takeLatest('@company/TRANSACTIONS_LIST_REQUEST', CompanyTransactionsRequest),
])
