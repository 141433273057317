import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import * as MyClientActions from '../../../store/modules/myclient/actions'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import apiPayment from '../../../services/apiPayment'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function Item({ item,index,handleInfoRightMenu }) {
  const [balance, setBalance] = useState(0)
  const theme = useTheme()


  useEffect(() => {
    setBalance(0)
    apiPayment
        .get(`/payment/manager/v1/web/balance/${item.id}/customer`)
        .then(({ data }) => {
          setBalance(data.balance)
        })
  }, [item])


  return (
      <>
        <TransactionItem key={index}>
          <div className="bar-container">
            <div
                className="bar"
                style={{
                  backgroundColor: theme.color['in'] || '#fff',
                }}
            ></div>
          </div>
          <div className="info">
            <span className="title">{item.name}</span>
            <span className="subtitle"> {item.email}</span>
          </div>
          <div className={`price`}>
            {' '}
            <span className="clock">
                          {' '}
              {formatToReal(parseFloat(balance))}{' '}
                        </span>
          </div>
          <div
              className="action"
              onClick={() => {
                handleInfoRightMenu(item)
              }}
          >
            <i className="material-icons icon">navigate_next</i>
          </div>
        </TransactionItem>
      </>
  )
}
