import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Container,
  Page,
  ContainerInputs,
  ContainerHorizontal,
  SmallLabel,
  Label,
  ButtonClose
} from "../styles";

import Thumb from "./previewThumb";
import { HeaderBackImage } from "../../../components/Headers";
import { ButtonDefault } from "../../../components/Buttons";
import { registerPersonalDocument } from "../../../store/modules/register/actions";

const initialValues = {
  front_document: [],
  back_document: [],
};

const initialErrors = {
  front_document: "-",
  back_document: "-",
};

const DocumentSchema = Yup.object().shape({
  front_document: Yup.array().of(Yup.string()),
  back_document: Yup.array().of(Yup.string()),
});

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default function SignIn() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(-1);

  async function submitForm(values) {
    const front_document = await Promise.all(
      values.front_document.map(
        async v =>
          await toBase64(v).then(v =>
            v.replace(/image\/jpeg|image\/jpg/gi, "jpg")
          )
      )
    );
    const back_document = await Promise.all(
      values.back_document.map(
        async v =>
          await toBase64(v).then(v =>
            v.replace(/image\/jpeg|image\/jpg/gi, "jpg")
          )
      )
    );

    console.log({ front_document: front_document[0], back_document: back_document[0] })
    dispatch(
      registerPersonalDocument({ front_document: front_document[0], back_document: back_document[0] })
    );
  }

  const handleToSet = value => {
    setCurrent(value);
  };

  const validInput = ({ input, props }) => {
    if (props.errors[input]) {
      return false;
    } else {
      return true;
    }
  };

  const openSelectImage = async ({ event, index, inputName, props }) => {
    props.setFieldValue(`${inputName}[${index}]`, event.currentTarget.files[0]);
    event.target.value = "";
  };

  const handleRemoveImage = ({ name, index, props }) => {
    const newValues = props.values[name].filter((v, i) => i != index);
    props.setFieldValue(name, newValues);
  };

  return (
    <Container>
      <section className="container-small">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          initialErrors={initialErrors}
          initialTouched={initialValues}
          validationSchema={DocumentSchema}
        >
          {props => (
            <>
              {/* MESSAGE [-1] */}
              {current === -1 && (
                <Page>
                  <HeaderBackImage onClick={() => history.goBack()} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      Quase lá, agora cadastraremos os{" "}
                      <strong>documentos do sócio</strong>
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Próximo"
                    onClick={() => handleToSet(0)}
                  />
                </Page>
              )}

              {/* documentos do usuário [0] */}
              {current === 0 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos do usuário.</strong>
                      <br></br>
                      <p>Frente do documento.</p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>
                      {
                        props.values.front_document.length == 0 ? (
                          <>
                            <SmallLabel
                              style={{
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                              htmlFor={`default_file_0`}
                              className="label-file label-open"
                            >
                              <i className="fas fa-plus " />
                            </SmallLabel>
                            <input
                              id={`default_file_0`}
                              type="file"
                              accept=".jpg"
                              autoCapitalize="none"
                              placeholder=""
                              onChange={event =>
                                openSelectImage({
                                  event,
                                  inputName: "front_document",
                                  index: props.values.front_document.length,
                                  props
                                })
                              }
                            />
                          </>
                        ) : (
                          <div key={0}>
                            <Label style={{ cursor: "initial" }}>
                              <ButtonClose
                                onClick={() =>
                                  handleRemoveImage({
                                    name: "front_document",
                                    index: 0,
                                    props
                                  })
                                }
                              >
                                <i className="fa fa-times" />
                              </ButtonClose>
                              <Thumb file={props.values.front_document[0]} />
                            </Label>
                          </div>

                        )
                      }
                    </ContainerHorizontal>
                    <span>
                      {props.touched.front_document &&
                        props.errors.front_document}
                    </span>
                  </ContainerInputs>
                  {props.values.front_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(1)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(-1)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* documentos da companhia back_document [1] */}
              {current === 1 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos do usuário.</strong>
                      <br></br>
                      <p>Verso do documento.</p>
                    </label>
                    <ContainerHorizontal style={{ flexDirection: "row" }}>

                      {
                        props.values.back_document.length == 0 ? (
                          <>
                            <SmallLabel
                              style={{
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                              htmlFor={`default_file_1`}
                              className="label-file label-open"
                            >
                              <i className="fas fa-plus " />
                            </SmallLabel>
                            <input
                              id={`default_file_1`}
                              type="file"
                              accept=".jpg"
                              autoCapitalize="none"
                              placeholder=""
                              onChange={event =>
                                openSelectImage({
                                  event,
                                  inputName: "back_document",
                                  index: 0,
                                  props
                                })
                              }
                            />
                          </>
                        ) : (

                          <div key={0}>
                            <Label style={{ cursor: "initial" }}>
                              <ButtonClose
                                onClick={() =>
                                  handleRemoveImage({
                                    name: "back_document",
                                    index: 0,
                                    props
                                  })
                                }
                              >
                                <i className="fa fa-times" />
                              </ButtonClose>
                              <Thumb file={props.values.back_document[0]} />
                            </Label>
                          </div>

                        )
                      }



                    </ContainerHorizontal>
                    <span>
                      {props.touched.back_document &&
                        props.errors.back_document}
                    </span>
                  </ContainerInputs>
                  {props.values.back_document.length ? (
                    <ButtonDefault
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  ) : (
                    <ButtonDefault
                      disabled={true}
                      background={theme.color.white}
                      color={theme.color.blackButton}
                      title="Próximo"
                      onClick={() => handleToSet(2)}
                    />
                  )}
                  <ButtonDefault
                    onClick={() => handleToSet(0)}
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    title="Voltar"
                  />
                </Page>
              )}

              {/* CHECK [3] */}
              {current === 2 && (
                <Page>
                  <HeaderBackImage onClick={() => handleToSet(-1)} />
                  <ContainerInputs style={{ justifyContent: "flex-start" }}>
                    <label>
                      <strong>Documentos da empresa</strong>
                      <br />
                      Aṕós enviar seus dados vamos precisar dos documentos da empresa
                    </label>
                  </ContainerInputs>
                  <ButtonDefault
                    type="submit"
                    background={theme.color.blackButton}
                    color={theme.color.white}
                    title="Confirmar"
                    onClick={props.handleSubmit}
                  />
                </Page>
              )}
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
