import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ContainerInputs, TypePage } from './styles'
import { ButtonDefault } from '../../Buttons'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as TransferActions from '../../../store/modules/transfer/actions'
import * as PixActions from '../../../store/modules/pix/actions'
import * as AccountActions from '../../../store/modules/account/actions'
import Limit from '../../Limit'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { InputMask } from '../../Inputs'
import { cpfIsValid } from '../../../utils/check'
import DisplayInfos from '../../DisplayInfo'

const DepositSchema = Yup.object().shape({
  amount: Yup.string().required('campo obrigatório'),
})

const diplayValues = [
  { key: 'name', name: 'Nome', value: '', position: 'row' },
  { key: 'key_type', name: 'Tipo da chave', value: '', position: 'row' },
  {
    key: 'key_key_value',
    name: 'Chave',
    value: '',
    position: 'row',
  },
  { key: 'amount', name: 'Valor', value: '', position: 'row' },
]

const initialValues = {
  account: "",
  amount: "",
  branch: "",
  bank_number: "",
  document: "",
  name: "",
  type: "PF",
  pix_key: "",
  pix_type: "SELECIONAR",
  transfer_type: "",

}

const TransferCreate = ({ navigation }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [types] = useState(['PF', 'PJ'])
  const [typesPixes] = useState([
    'SELECIONAR',
    'CNPJ',
    'EMAIL',
    'PHONE',
    'EVP',
    'CPF',
  ])
  const [success, setSuccess] = useState(false)
  const [page, setPage] = useState(1)

  const [typeTranfer, setTypeTransfer] = useState("")

  const { bank } = useSelector((state) => state.transfer)

  const { limits } = useSelector((state) => state.account)

  const handleCancel = () => {
    history.push('/financeiro/transferencia')
  }

  const GetKey = (props) => {
    let pix_key = ''
    switch (props.values.pix_type) {
      case 'EVP':
      case 'EMAIL':
        pix_key = props.values.pix_key
        break
      default:
        pix_key = props.values.pix_key.replace(/\D/g,'')
        break
    }
    dispatch(
      PixActions.GetExternalKey({
        key: pix_key,
        callback: (data) => {
          props.setFieldValue('key_key_value', data.addressingKey.value)
          props.setFieldTouched('key_key_value', true)
          props.setFieldValue('key_type', data.addressingKey.type)
          props.setFieldTouched('key_type', true)
          props.setFieldValue('name', data.holder.name)
          props.setFieldTouched('name', true)
          setPage(4)
        },
      }),
    )
  }

  useEffect(() => {
    dispatch(
      TransferActions.banksRequest({
        per_page: 100000,
        page: 1,
        query: {  },
      }),
    )
    dispatch(AccountActions.ListLimits())
  }, [dispatch])

  const handleSubmitForm = (values) => {
    let pix_key = ''
    switch (values.pix_type) {
      case 'EVP':
      case 'EMAIL':
        pix_key = values.pix_key
        break
      default:
        pix_key = values.pix_key.replace(/\D/g,'')
        break
    }

    console.log(values.pix_key)

    const document = values.document.replace(/[\.^\-^\/]/gi, "");
    const amount = values.amount.replace(/[\.^\R$\ ^\/]/gi, "");
    dispatch(
      TransferActions.newTransfer({
        ...values,
        document,
        pix_key,
        amount: parseFloat(amount),
        account: values.account,
        branch: values.branch,
        callback: () => setSuccess(true),

      }),
    )
  }

  const changeType = (props, page) => {
    switch (page) {
      case 2:
        props.setFieldValue("account", "-");
        props.setFieldError("account", false);
        props.setFieldTouched("account", true);
        props.setFieldValue("branch", "-");
        props.setFieldTouched("branch", true);
        props.setFieldValue("bank_number", "-");
        props.setFieldTouched("bank_number", true);
        props.setFieldValue("name", "-");
        props.setFieldTouched("name", true);
        props.setFieldValue("document", "-");
        props.setFieldTouched("document", true);
        props.setFieldValue("transfer_type", "pix");
        break;
      case 3:
        props.setFieldValue("pix_key", "-");
        props.setFieldTouched("pix_key", true);
        props.setFieldValue("transfer_type", "ted");
        break;
    }
    setPage(page);

  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <p>Transferência feita com sucesso</p>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={DepositSchema}
        onSubmit={handleSubmitForm}
      >
        {(props) => (
          <>
            {page == 1 && (
              <TypePage>
                <ButtonDefault
                  title="TED/DOC"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => changeType(props, 3)}
                />
                <ButtonDefault
                  title="PIX"
                  background={theme.color.white}
                  width="170px;"
                  onClick={() => changeType(props, 2)}
                />
              </TypePage>
            )}
            {page == 2 && (
              <>
                <ContainerInputs>
                  <label htmlFor="document">Tipo</label>
                  <select
                    type="text"
                    name="pix_type"
                    placeholder="Tipo"
                    value={props.values.pix_type}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  >
                    {typesPixes.map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  </select>
                </ContainerInputs>
                <div className="field">
                  <input
                    type="text"
                    name="pix_key"
                    placeholder="Chave"
                    value={props.values.pix_key}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                </div>
                <div className="field">
                  <InputMask
                    autocomplete="off"
                    format="money"
                    // mask="/^\d(\.\d)*$/"
                    type="string"
                    name="amount"
                    placeholder="Valor"
                    value={props.values.amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    min={limits.find((item) => item.name == 'transfer-pix').min}
                  ></InputMask>
                  <span>{props.touched.amount && props.errors.amount}</span>
                </div>
                <div className="field">
                  {limits.length > 0 && (
                    <Limit
                      volume={
                        limits.find((item) => item.name == 'transfer-pix')
                          .volume
                      }
                      balance={
                        limits.find((item) => item.name == 'transfer-pix')
                          .balance
                      }
                      amount={props.values.amount}
                      percent={
                        limits.find((item) => item.name == 'transfer-pix')
                          .percent
                      }
                    />
                  )}
                </div>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Salvar"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={() => GetKey(props)}
                    />
                    <ButtonDefault
                      title="Voltar"
                      background={theme.color.white}
                      width="170px;"
                      onClick={() => setPage(1)}
                    />
                  </div>
                </div>
              </>
            )}
            {page == 3 && (
              <>
                <div className="field">
                  <Typeahead
                    d="bank"
                    labelKey={(options) =>
                      `${options.bank_number} - ${options.bank_name}`
                    }
                    autocomplete={false}
                    options={bank.data}
                    filterBy={['bank_number', 'bank_name']}
                    ignoreDiacritics={false}
                    emptyLabel="Não existe"
                    onChange={(selected) => {
                      if (selected.length > 0) {
                        
                        props.setFieldValue(
                          'bank_number',
                          selected[0].bank_number,
                        )
                      }
                    }}
                    multiple={false}
                  />
                  <span>
                    {props.touched.bank_number && props.errors.bank_number}
                  </span>
                </div>
                <div className="horizontal">
                  <div className="field">
                    <input
                      type="text"
                      name="branch"
                      placeholder="Agência"
                      value={props.values.branch}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>{props.touched.branch && props.errors.branch}</span>
                  </div>
                  <div className="fieldNoMargin">
                    <input
                      type="text"
                      name="account"
                      placeholder="Conta"
                      value={props.values.account}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></input>
                    <span>{props.touched.account && props.errors.account}</span>
                  </div>
                </div>

                <div className="field">
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.name && props.errors.name}</span>
                </div>
                <ContainerInputs>
                  <label htmlFor="document">Tipo</label>
                  <select
                    type="text"
                    name="type"
                    placeholder="Tipo"
                    value={props.values.type}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  >
                    {types.map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  </select>
                  <span>
                    {console.log(props.errors)}
                    {props.touched.type && props.errors.type}
                  </span>
                </ContainerInputs>

                <div className="field">
                  <InputMask
                    format={props.values.type == 'PF' ? 'cpf' : 'cnpj'}
                    type="text"
                    name="document"
                    placeholder="Documento do titular"
                    value={props.values.document}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></InputMask>
                  <span>{props.touched.document && props.errors.document}</span>
                </div>
                <div className="field">
                  <InputMask
                    autocomplete="off"
                    format="money"
                    // mask="/^\d(\.\d)*$/"
                    type="string"
                    name="amount"
                    placeholder="Valor"
                    value={props.values.amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    min={limits.find((item) => item.name == 'transfer-ted').min}
                  ></InputMask>
                  <span>{props.touched.amount && props.errors.amount}</span>
                </div>
                <div className="field">
                  {limits.length > 0 && (
                    <Limit
                      volume={
                        limits.find((item) => item.name == 'transfer-ted')
                          .volume
                      }
                      balance={
                        limits.find((item) => item.name == 'transfer-ted')
                          .balance
                      }
                      amount={props.values.amount}
                      percent={
                        limits.find((item) => item.name == 'transfer-ted')
                          .percent
                      }
                    />
                  )}
                </div>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Salvar"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={props.handleSubmit}
                    />
                    <ButtonDefault
                      title="Voltar"
                      background={theme.color.white}
                      width="170px;"
                      onClick={() => setPage(1)}
                    />
                  </div>
                </div>
              </>
            )}
            {page == 4 && (
              <>
                <div className="field">
                  <DisplayInfos
                    items={diplayValues.map((item) => {
                      item.value = props.values[item.key]
                      return item
                    })}
                  />
                </div>
                <div className="container-buttons">
                  <div className="horizontal">
                    <ButtonDefault
                      title="Salvar"
                      background={theme.color.blackButton}
                      color={theme.color.white}
                      width="170px;"
                      onClick={props.handleSubmit}
                    />
                    <ButtonDefault
                      title="Voltar"
                      background={theme.color.white}
                      width="170px;"
                      onClick={() => setPage(1)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Formik>
    </Container>
  )
}

export default TransferCreate
