import React, { useState, useEffect, CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ContainerPage, ContainerPagination } from './styles'
import { ButtonDefault } from '../../Buttons'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as InviteActions from '../../../store/modules/invite/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { InputMask } from '../../Inputs'
import { cpfIsValid } from '../../../utils/check'
import Carousel from 'react-elastic-carousel'
import apiCep from '../../../services/apiCEP.js'
import * as GeneralActions from '../../../store/modules/general/actions'
import { setOnClipboard } from "../../../utils/clipboard";
import DisplayInfos from '../../DisplayInfo'

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success,
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const ClientSchema = Yup.object().shape({
  email: Yup.string().required('campo obrigatório'),
})

const initialValues = {
  email: '',
}

const initialErrors = {
  email: '-',
}

const diplayValues = [
  { key: 'email', name: 'Nome', value: '', position: 'row' },
]

const MyClientCreate = ({ navigation }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [position, setPosition] = useState(0)
  const [code, setCode] = useState('')

  var carousel = null

  const [success, setSuccess] = useState(false)

  const { bank } = useSelector((state) => state.transfer)

  useEffect(() => {}, [])

  var listRef = null

  const handleCancel = () => {
    history.push('/crm/cliente')
  }

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const handleNextPage = (props) => {
    console.log(position)
    switch (position) {
      case 0:
        const { email } = props.values
        props.validateForm().then((validation) => props.setTouched(validation))
        ClientSchema.isValid({ email }).then((valid) => {
          if (valid) {
            carousel.goTo(position + 1)
            setPosition(position + 1)
            return
          }
          dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
        })
        break
      case 1:
        handleSubmitForm(props.values)
        break
      case 2:
        break
      default:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
    }
  }

  const handleSubmitForm = (values) => {
    console.log(values)
    dispatch(
      InviteActions.newInvite({
        ...values,
        callback: ({ code }) => {
          setSuccess(true)
          setCode(code)
        },
      }),
    )
  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">Invite criado com sucesso</div>
          </div>
          <ButtonDefault
            title="Copiar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={() =>
              setOnClipboard(`https://conta.trocacoin.io/register?code=${code}`)
            }
          />
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ClientSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Carousel
              showArrows={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(
                  renderPropos,
                  props,
                  handlePrevPage,
                  success,
                )
              }}
            >
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.email && props.errors.email}</span>
                </div>
              </ContainerPage>
              <ContainerPage>
                {position == 1 && (
                  <DisplayInfos
                    items={diplayValues.map((item) => {
                      item.value = props.values[item.key]
                      return item
                    })}
                  />
                )}
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default MyClientCreate
