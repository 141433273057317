import { all, takeLatest, put, call, select, takeEvery } from "redux-saga/effects";
import history from "../../../services/history";
import apiPayment from "../../../services/apiPayment";
import GetErrors from "../../../services/errors";
import * as TransferActions from "./actions";
import * as GeneralActions from "../general/actions";
import moment from "moment";

export function* transactionsRequest(action) {
  try {
    const { per_page, page, query,order_by,callback } = action.payload;

    yield put(GeneralActions.setLoading({ loading: true }));
    const { data } = yield call(apiPayment.post, "/payment/manager/v1/web/transactions", {
      per_page,
      page,
      query,
      order_by
    });
    if(callback != undefined){
      callback(data)
    }
    yield put(TransferActions.transactionsRequestSuccess(data));
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error}));
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }));
  }
}

export default all([
  takeEvery("@transaction/TRANSACTIONS_REQUEST", transactionsRequest),
]);
