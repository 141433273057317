import React, {useEffect, useCallback, useState} from 'react'
import {
    formatToReal,
    formatDate,
    formatCnpj,
    formatHour,
} from '../../../utils/format'
import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    Image,
} from '@react-pdf/renderer'
import '../../../assets/animations/animations.css'

import logoIcon from "../../../assets/images/packman-white.png";
import logoIconTroca from "../../../assets/images/troca-logo.png";


export default function ExtractPdf({transactions, company, balance, account, to, from}) {


    return (
        <Document>
            <Page
                size="A4"
                style={{paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35}}
            >
                <View>
                    <Image
                        style={{
                            height: 50,
                            width: 50,
                        }}
                        src={logoIconTroca}
                    />
                </View>
                <Text style={{fontSize: 24, textAlign: 'left', marginTop: 12}}>
                    Extrato bancário
                </Text>

                <View style={{paddingVertical: 8}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View>
                            <Text
                                style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                            >
                                Nome:{' '}
                            </Text>
                            <Text style={{marginTop: 4, fontSize: 10}}>
                                {' '}
                                {company.name}{' '}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View>
                            <Text
                                style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                Situação:{' '}
                            </Text>
                            <Text style={{marginTop: 4, fontSize: 10}}>
                                {' '}
                                LIBERADA
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Instituição:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {company.bank_name} -  {company.brank_number}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Chave pix:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {company.pix_key}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Conta Principal:{' '}
                            </Text>
                        </View>
                        <View>
                            <Text
                                style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                Nome:{' '}
                            </Text>
                            <Text style={{marginTop: 4, fontSize: 10}}>
                                {' '}
                                {account.name}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap',borderBottom: '1px dashed black',paddingVertical: 4}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Documento:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {formatCnpj(account.document)}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{ marginRight:10}}>
                            <Text
                                style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                            >
                                Data Inicial:{' '}
                            </Text>
                            <Text style={{marginTop: 4, fontSize: 10}}>
                                {' '}
                                {formatDate(from)}
                            </Text>
                        </View>
                        <View>
                            <Text
                                style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                Data Final:{' '}
                            </Text>
                            <Text style={{marginTop: 4, fontSize: 10}}>
                                {' '}
                                {formatDate(to)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{borderTop: '1px dashed black',}}>
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 12}}
                            >

                            </Text>
                            <Text style={{fontSize: 10}}>
                                {' '}

                            </Text>
                        </View>
                        <View style={{flex: 6}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                Descrição:{' '}
                            </Text>
                            <Text style={{fontSize: 10}}>
                                {' '}

                            </Text>
                        </View>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                Valor:{' '}
                            </Text>
                            <Text style={{fontSize: 10}}>
                                {' '}

                            </Text>
                        </View>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 12}}
                            >
                                {' '}
                                D/C:{' '}
                            </Text>
                            <Text style={{fontSize: 10}}>
                                {' '}

                            </Text>
                        </View>
                    </View>
                    {transactions.map((item, index) => (
                        <>
                            <View style={{display: 'flex', flexDirection: 'row' , marginVertical:10}}>
                                <View style={{flex: 2}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >

                                    </Text>
                                </View>
                                <View style={{flex: 6}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >
                                        {item.key}
                                    </Text>

                                </View>
                                <View style={{flex: 2}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >

                                    </Text>

                                </View>
                                <View style={{flex: 2}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >
                                        {' '}

                                    </Text>

                                </View>
                            </View>

                            {item.data.map((b, indexData) => (
                                <View style={{display: 'flex', flexDirection: 'row'}}>
                                    <View style={{flex: 2}}>
                                        <Text
                                            style={{fontWeight: 'bold', fontSize: 11}}
                                        >

                                        </Text>
                                    </View>
                                    <View style={{flex: 6}}>
                                        <Text
                                            style={{fontWeight: 'bold', fontSize: 11}}
                                        >
                                            {b.title == '' ? b.category_name : b.title}
                                        </Text>
                                    </View>
                                    <View style={{flex: 2}}>
                                        <Text
                                            style={{fontWeight: 'bold', fontSize: 11}}
                                        >
                                            {formatToReal(parseFloat(b.amount))}
                                        </Text>

                                    </View>
                                    <View style={{flex: 2}}>
                                        <Text
                                            style={{fontWeight: 'bold', fontSize: 12}}
                                        >
                                            {' '}
                                            {b.operation_direct == "in" ? "C":"D"}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                            <View style={{display: 'flex', flexDirection: 'row' , marginVertical:10 ,marginBottom:15 }}>
                                <View style={{flex: 2}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >

                                    </Text>
                                </View>
                                <View style={{flex: 6,borderBottom: '1px dashed black'}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11,marginBottom:10}}
                                    >
                                        SALDO DISPONIVEL
                                    </Text>

                                </View>
                                <View style={{flex: 2,borderBottom: '1px dashed black', }}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11,marginBottom:10}}
                                    >
                                        {formatToReal(parseFloat(item.balance))}
                                    </Text>

                                </View>
                                <View style={{flex: 2}}>
                                    <Text
                                        style={{fontWeight: 'bold', fontSize: 11}}
                                    >
                                        {' '}

                                    </Text>

                                </View>
                            </View>
                        </>
                    ))}
                    <View style={{display: 'flex', flexDirection: 'row' , marginVertical:10}}>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 11}}
                            >
                                {formatDate(new Date())}
                            </Text>
                        </View>
                        <View style={{flex: 6}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 11}}
                            >
                                TOTAL DISPONÍVEL PARA TRANSAÇÕES
                            </Text>

                        </View>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 11}}
                            >
                                {formatToReal(balance)}
                            </Text>

                        </View>
                        <View style={{flex: 2}}>
                            <Text
                                style={{fontWeight: 'bold', fontSize: 11}}
                            >
                                {' '}

                            </Text>

                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
