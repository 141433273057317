import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history'
import apiPayment from '../../../services/apiPayment'
import GetErrors from '../../../services/errors'
import * as TransferActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'

export function* transfersRequest(action) {
  try {
    const { per_page, page, query } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/transfers',
      {
        per_page,
        page,
        query,
      },
    )
    yield put(TransferActions.transfersRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* transferGet(action) {
  try {
    const { id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.get,
      `/payment/manager/v1/web/transfer/${id}`,
    )
    yield put(TransferActions.Detail(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* ResendCode(action) {
  try {
    const { id, callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/transfer/approve/code',
      {
        id,
      },
    )
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* banksRequest(action) {
  try {
    const { per_page, page, query } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/transfer/banks',
      {
        per_page,
        page,
        query,
      },
    )
    yield put(TransferActions.banksRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* confirmTransfer(action) {
  try {
    let request = action.payload
    const { id, code, callback } = action.payload

    yield put(GeneralActions.setLoading({ loading: true }))

    yield call(apiPayment.post, '/payment/partner/v1/web/transfer/approve', {
      id,
      code,
    })

    yield put(TransferActions.transfersRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newTransfer(action) {
  try {
    let request = action.payload
    const { callback } = action.payload

    yield put(GeneralActions.setLoading({ loading: true }))
    const {
      account,
      amount,
      branch,
      bank_number,
      document,
      name,
      transfer_type,
      pix_key,
      pix_type,
    } = request

    yield call(apiPayment.post, '/payment/partner/v1/web/transfer', {
      name,
      account,
      amount,
      branch,
      bank_number,
      document,
      pix_key,
      pix_type,
      type: transfer_type,
    })

    yield put(TransferActions.transfersRequest({ page: 1, per_page: 3 }))
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* accountsRequest(action) {
  try {
    const { per_page, page, query } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/transfer/accounts',
      {
        per_page,
        page,
        query,
      },
    )
    console.log(data)
    yield put(TransferActions.accountsRequestSuccess(data))
  } catch (error) {
    console.log(error)

    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newAccount(action) {
  try {
    const {
      callback,
      account,
      branch,
      bank_number,
      document,
      supplier_id,
      name,
    } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))

    yield call(apiPayment.post, '/payment/partner/v1/web/transfer/account', {
      name,
      account,
      branch,
      bank_number,
      document,
      supplier_id,
    })
    yield put(
      TransferActions.accountsRequest({
        per_page: 20,
        page: 1,
        query: { supplier_id },
      }),
    )
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export default all([
  takeLatest('@transfer/TRANSFERS_REQUEST', transfersRequest),
  takeLatest('@transfer/TRANSFER_GET', transferGet),
  takeLatest('@transfer/BANKS_REQUEST', banksRequest),
  takeLatest('@transfer/NEW_TRANSFER', newTransfer),
  takeLatest('@transfer/ACCOUNTS_REQUEST', accountsRequest),
  takeLatest('@transfer/NEW_ACCOUNT', newAccount),
  takeLatest('@tranfer/RESEND_CODE', ResendCode),
  takeLatest('@tranfer/CONFIRM', confirmTransfer),
])
