import React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { CardPayBillCreate } from "../../../components/Cards";
import { Container } from "./styles";




export default function NewDeposit() {
  const history = useHistory();

  return (
    <Container>
      <h1>Pagar uma conta</h1>
      <p>Você pode pagar um boleto ou uma conta</p>
      <div className="horizontal"></div>
      <CardPayBillCreate />
    </Container>
  );
}
