import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import { Container, Break, ContainerItem, Item } from "./styles";
import { formatToReal } from "../../utils/format";
import * as TransactionActions from "../../store/modules/transaction/actions";
import Paginator from "../../components/Paginator";

import { CardHomeEntries } from "../../components/Cards";

const types = ["Pagamentos", "Transporte"];

const payments = [
  // {
  //   name: "Depósito",
  //   icon: "trending_down",
  //   iconType: "icon",
  //   href: "/financeiro/deposito",
  // },
  // {
  //   name: "Transferência",
  //   icon: "trending_up",
  //   iconType: "icon",
  //   href: "/financeiro/transferencia",
  // },
];

export default function CRM() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {}, [dispatch]);

  const renderIcon = (item) => {
    switch (item.iconType) {
      case "icon":
        return <i className="material-icons">{item.icon}</i>;
      case "image":
        return <img height={60} height={60} src={item.image} />;
    }
  };

  return (
    <Container>
      <h1>Financeiro</h1>
      <div className="horizontal">
        <p>Controle total das suas finanças </p>
      </div>
      <ContainerItem>
        {payments.map((item) => (
          <Item onClick={() => history.push(item.href)}>
            <div className="title">{item.name}</div>
            <div className="icon">{renderIcon(item)}</div>
          </Item>
        ))}
      </ContainerItem>
    </Container>
  );
}
