export default {
  color: {
    background: "#EFEFEF",
    blackButton: "#272626",
    blackText: "#434544",
    blackTextWelcome: "#585858",
    gray: "#707070",
    white: "#FFFFFF",
    home: "#EFEFEF",
    white2: "#F2F6FB",
    grayText: "#747474",
    green: "#07EA93",
    green2: "#A8C40B",
    blue: "#1AA3E0",
    text: "#404040",
    red: "#D14848",
    greenValue: "#68F4A8",
    pending: "#D0C000",
    paid: "#07EA93",
    error: "#EA4407",
    cancel: "#EA4407",
    scheduled: "#07A4EA",
    create: "#D0C000",
    done: "#07EA93",
    in: " #07EA93",
    out: "#EA0744",
    send: "#D0C000",
  },
  font: {
    extralarger: 22,
    larger: 18,
    medium: 14,
    small: 12,
    extrasmall: 10,
  },
};
