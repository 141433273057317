import React, { useEffect } from "react";
import history from "../../services/history";
import { Container, ButtonLogin, ButtonRegister } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import * as GeneralActions from "../../store/modules/general/actions";

import pacman from "../../assets/images/pac-man.png";
import logo from "../../assets/images/logo-black.png";

import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";

export default function Login() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.user);
  console.log(process.env);

  //TODO/ checar steps para cadastro
  useEffect(() => {}, [token]);

  return (
    <Container>
      <section className="container-small">
        {/* <img src={pacman} alt="pacman" /> */}
        <img src={logo} alt="logo" />
        <div>
          <ButtonDefault
            onClick={() => history.push("login")}
            color={theme.color.white}
            background={theme.color.blackButton}
            title="Login"
          />
        </div>
      </section>
    </Container>
  );
}
