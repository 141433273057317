import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ContainerWrapper = styled.div`
  flex-direction: row;
  flex-flow: 1 row nowrap;
  -webkit-flex-flow: row nowrap;
 
`

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.blackButton};
  height: 70px;
  border-top-right-radius: 30px;
  .logo {
    padding: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`

export const ContainerMenu = styled.nav`
  border-top-right-radius: 30px;
  .logo {
    padding: 10px;
  }

  &.hide {
    background-color: #efefef;
  }
  @media (max-width: 609px) {
    display: none;
    width: 0;
  }
  background-color: ${({ theme }) => theme.color.blackButton};
`

export const ContainerChildren = styled.section`
  height: 100vh;
  width: 100%;
  /* flex: 1;
  overflow: auto; */
  background-color: ${({ theme }) => theme.color.background};
  @media (max-width: 609px) {
   
  }
`

export const Scroll = styled(ContainerChildren)`
  flex: 1;
  flex-flow: column;
  -webkit-flex-flow: column;
  overflow: auto;
  /* height: calc(100vh - 70px); */
  /* height: 100%; */
`

export const List = styled.ul`
  background-color: ${({ theme }) => theme.color.blackButton};
  &.hide {
    display: none;
  }
    padding: 0;
`

export const ListItem = styled(Link)`
  color: ${({ theme }) => theme.white};
  padding: 10px;
  flex-direction: column;
  flex-flow: column;
  -webkit-flex-flow: column;
  justify-content: center;
  -webkit-justify-content: center;
  height: 60px;
  align-items: center;
  opacity: ${({ current }) => (current ? 1 : 0.5)};
  color: #fff !important;
text-decoration: none;
  cursor: pointer;

  p {
    font-size: 8px;
    opacity: 0;
  }

  &:hover {
    p {
      opacity: 1;
    }
    .flag-marked {
      opacity: 1;
    }
    opacity: 1;
    border-width: 5px;
  }

  .flag-marked {
    opacity: ${({ current }) => (current ? 1 : 0)};
    left: 0px;
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: #fff;
  }
`

export const ContainerMenuBottom = styled.nav`
  border-top-right-radius: 30px;
  width: 100%;
  height: 20px;
  display: flex;
  position: fixed;
  bottom: 30px;
  left: 0;
  margin: 0;

  .logo {
    padding: 10px;
  }
  display: none;

  &.hide {
    background-color: #efefef;
  }
  @media (max-width: 609px) {
    display: block;
  }
  background-color: ${({ theme }) => theme.color.blackButton};
`

export const ListBottom = styled.ul`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.blackButton};
  &.hide {
    display: none;
  }
  justify-content: space-between;
 
`

export const ListItemBottom = styled(Link)`
  color: ${({ theme }) => theme.white};
  padding: 10px;
  flex-direction: column;
  flex-flow: column;
  -webkit-flex-flow: column;
  justify-content: center;
  -webkit-justify-content: center;
  height: 60px;
  align-items: center;
  opacity: ${({ current }) => (current ? 1 : 0.5)};
  color: #fff !important;
text-decoration: none;
  cursor: pointer;

  p {
    font-size: 8px;
    opacity: 1;
  }

  &:hover {
    p {
      opacity: 1;
    }
    .flag-marked {
      opacity: 1;
    }
    opacity: 1;
    border-width: 5px;
  }

  .flag-marked {
    opacity: ${({ current }) => (current ? 1 : 0)};
    left: 0px;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #fff;
    bottom: 0;
  }
`
