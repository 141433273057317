import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from "./styles";
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from "../../../utils/format";
import * as TransactionActions from "../../../store/modules/transaction/actions";
import { ButtonDefault } from "../../../components/Buttons";
import Paginator from "../../../components/Paginator";
import Pagination from "@material-ui/lab/Pagination";
import * as AccountActions from "../../../store/modules/account/actions";
import * as TransferActions from "../../../store/modules/transfer/actions";
import * as DepositActions from "../../../store/modules/deposit/actions";
import * as UserActions from "../../../store/modules/user/actions";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import "../../../assets/animations/animations.css";
import CloseIcon from "../../../assets/images/close.png";
import logoIcon from "../../../assets/images/packman-white.png";

export default function Transaction() {
  const [rightMenu, setRightMenu] = useState(false);
  const [rightMenuInfo, setRightMenuInfo] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [selectType, setSelectType] = useState("transfer");

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  let { data, page, pages, total, per_page } = useSelector(
    (state) => state.transaction
  );
  const { balance, balance_block } = useSelector((state) => state.account);
  const { transfer } = useSelector((state) => state.transfer);
  const { deposit } = useSelector((state) => state.deposit);
  const account = useSelector((state) => state.account.account);

  useEffect(() => {
    // dispatch(AccountActions.balanceRequest());
    // dispatch(AccountActions.GetAccount());
    // dispatch(
    //   TransactionActions.transactionsRequest({ per_page: per_page, page: 1 })
    // );
  }, [dispatch]);
  useEffect(() => {
    if (data.length > 0) {
      let dataFormated = [];
      data.forEach((item) => {
        let checktIndex = dataFormated.findIndex(
          (el) => el.key == formatDate(item.createdAt)
        );
        if (checktIndex > -1) {
          dataFormated[checktIndex].data.push(item);
        } else {
          dataFormated.push({ key: formatDate(item.createdAt), data: [item] });
        }
      });

      setTransaction(dataFormated);
    }
  }, [data]);

  const handlePagePress = useCallback((event, value) => {
    dispatch(
      TransactionActions.transactionsRequest({
        per_page: per_page,
        page: value,
      })
    );
  });

  const renderView = (type) => {
    switch (type) {
      case "transfer":
        return renderTransfer();
      case "deposit":
        return renderDeposit();
    }
  };
  const renderTransfer = () => (
    <>
      <HeaderRigthModal>
        {" "}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false);
          }}
        />{" "}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="transfer">Enviado</LabelType>
        <LabelRightMenu> Tipo: </LabelRightMenu>
        <ContentRightMenu> {transfer.type} </ContentRightMenu>
        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu> {formatDate(transfer.updatedAt)} </ContentRightMenu>
        <LabelRightMenu> Nome do Destino: </LabelRightMenu>
        <ContentRightMenu> {transfer.name} </ContentRightMenu>

        <LabelRightMenu> Valor: </LabelRightMenu>
        <ContentRightMenu> {formatToReal(parseFloat(transfer.amount))} </ContentRightMenu>

        <LabelRightMenu> Documento: </LabelRightMenu>
        <ContentRightMenu> {transfer.document} </ContentRightMenu>

        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu> {transfer.bank_name} </ContentRightMenu>

        <LabelRightMenu> Origem: </LabelRightMenu>
        <ContentRightMenu> {account.name} </ContentRightMenu>

        <LabelRightMenu> CNPJ: </LabelRightMenu>
        <ContentRightMenu> {formatCnpj(account.document)} </ContentRightMenu>
        <LabelRightMenu> ID da transação: </LabelRightMenu>
        <ContentRightMenu> {transfer.integration_id} </ContentRightMenu>
        <ContentRightMenu>
          {transfer.integration_id && (
            <PDFDownloadLink
              document={
                <DocumentTransfer
                  transfer={transfer}
                  account={account}
                />
              }
              fileName="deposit.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <ButtonDownload
                    title="Baixar"
                    background={theme.color.green}
                    color={theme.color.white}
                    height="50px"
                    href={url}
                    target="_blank"
                    download={transfer.protocol}
                  >
                    Baixar
                  </ButtonDownload>
                )
              }
            </PDFDownloadLink>
          )}
        </ContentRightMenu>
      </RigthModal>
    </>
  );
  const renderDeposit = () => (
    <>
      <HeaderRigthModal>
        {" "}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false);
          }}
        />{" "}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="deposit">Recebido</LabelType>
        <LabelRightMenu> Tipo: </LabelRightMenu>
        <ContentRightMenu> {deposit.type} </ContentRightMenu>

        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu> {formatDate(deposit.updatedAt)} </ContentRightMenu>
        <LabelRightMenu>Nome da origem: </LabelRightMenu>
        <ContentRightMenu> {deposit.sender_name} </ContentRightMenu>

        <LabelRightMenu> Valor: </LabelRightMenu>
        <ContentRightMenu> {formatToReal(parseFloat(deposit.amount))} </ContentRightMenu>

        <LabelRightMenu> Documento: </LabelRightMenu>
        <ContentRightMenu> {deposit.sender_document} </ContentRightMenu>

        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu> {deposit.bank_name} </ContentRightMenu>
        <LabelRightMenu> ID da transação: </LabelRightMenu>
        <ContentRightMenu> {deposit.integration_id} </ContentRightMenu>
        <ContentRightMenu>
          {deposit.integration_id && (
            <PDFDownloadLink
              document={
                <DocumentDeposit
                  deposit={deposit}
                  account={account}
                />
              }
              fileName="deposit.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <ButtonDownload
                    title="Baixar"
                    background={theme.color.green}
                    color={theme.color.white}
                    height="50px"
                    href={url}
                    target="_blank"
                    download={deposit.protocol}
                  >
                    Baixar
                  </ButtonDownload>
                )
              }
            </PDFDownloadLink>
          )}
        </ContentRightMenu>
      </RigthModal>
    </>
  );
  const handleNewPress = () => {
    // history.push("/transferencia/novo");
  };

  const handleInfoRightMenu = (info) => {
    setSelectType(info.operation_type);
    switch (info.operation_type) {
      case "transfer":
        dispatch(
          TransferActions.transferGet({
            id: info.liquid_id,
          })
        );
        break;
      case "deposit":
        dispatch(
          DepositActions.depositGet({
            id: info.liquid_id,
          })
        );
        break;
    }

    setRightMenu(true);
    setRightMenuInfo(info);
    // history.push("/transferencia/novo");
  };

  const DocumentTransfer = ({ transfer, account }) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
        >
          <View>
            <Image
              style={{
                height: 50,
                width: 50,
                backgroundColor: "black",
              }}
              src={logoIcon}
            />
          </View>
          <Text style={{ fontSize: 24, textAlign: "left", marginTop: 12 }}>
            Comprovante de Transferência
          </Text>
          <Text style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}>
            {" "}
            Valor:{" "}
          </Text>
          <Text style={{ marginTop: 4, fontSize: 10 }}>
            {" "}
            {formatToReal(parseFloat(transfer.amount))}{" "}
          </Text>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Conta Origem:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  De:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {account.name}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Documento:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {formatCnpj(account.document)}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Instituição:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {account.bank_name}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Conta Destino:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  Para:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {transfer.name}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Documento:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {transfer.document}{" "}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Instituição:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {transfer.bank_name}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Informações:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  Tipo:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {transfer.type}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Data:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {formatDateHour(transfer.updatedAt)}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  ID da transação:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {transfer.integration_id}{" "}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };
  const DocumentDeposit = ({ deposit, account }) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
        >
          <View>
            <Image
              style={{
                height: 50,
                width: 50,
                backgroundColor: "black",
              }}
              src={logoIcon}
            />
          </View>
          <Text style={{ fontSize: 24, textAlign: "left", marginTop: 12 }}>
            Comprovante de Transferência
          </Text>
          <Text style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}>
            {" "}
            Valor:{" "}
          </Text>
          <Text style={{ marginTop: 4, fontSize: 10 }}>
            {" "}
            {formatToReal(parseFloat(deposit.amount))}{" "}
          </Text>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Conta Origem:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  De:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {deposit.sender_name}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Documento:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {deposit.sender_document}{" "}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Instituição:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {deposit.bank_name}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Conta Destino:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  Para:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {account.name}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Documento:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {formatCnpj(account.document)}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Instituição:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {account.bank_name}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{ borderBottom: "1px dashed black", paddingVertical: 4 }}
            >
              <Text style={{ marginTop: 12, fontWeight: "400", fontSize: 18 }}>
                {" "}
                Informações:{" "}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  Tipo:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {deposit.type}{" "}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  Data:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {formatDateHour(deposit.updatedAt)}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexWrap: "wrap" }}>
              <View>
                <Text
                  style={{ marginTop: 12, fontWeight: "bold", fontSize: 12 }}
                >
                  {" "}
                  ID da transação:{" "}
                </Text>
                <Text style={{ marginTop: 4, fontSize: 10 }}>
                  {" "}
                  {deposit.integration_id}{" "}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <ContainerItens>
      <Container>
        <h1>Extrato</h1>
        <div className="horizontal">
          <p>Todas as movimentações</p>
        </div>

        <Content>
          <div className="table">
            {!transaction.length && <p>nenhuma transferência realizada</p>}
            {!!transaction.length && (
              <>
                {transaction.map((item, index) => (
                  <>
                    <DateLabel key={index}>{item.key}</DateLabel>
                    {item.data.map((b, indexData) => (
                      <TransactionItem key={indexData}>
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor:
                                theme.color[b.operation_direct] || "#fff",
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">
                            {b.title == "" ? b.category_name : b.title}
                          </span>
                          <span className="subtitle">
                            {" "}
                            {b.operation_service}
                          </span>
                        </div>
                        <div className={`price`}>
                          {" "}
                          <span className={`amount ${b.operation_direct}`}>
                            {formatToReal(parseFloat(b.amount))}
                          </span>
                          <span className="clock">
                            {" "}
                            {formatHour(b.updatedAt)}{" "}
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            handleInfoRightMenu(b);
                          }}
                        >
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                    ))}
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
          <div className="balance">
            <div className="content">
              <div className="item">
                <div className="title">Saldo atual:</div>
                <div className="subtitle">{formatToReal(balance)}</div>
              </div>
              <div className="item">
                <div className="title">Saldo bloqueado:</div>
                <div className="subtitle">{formatToReal(balance_block)}</div>
              </div>
            </div>
          </div>
        </Content>
      </Container>

      <ContainerRigthModal className={rightMenu ? "show-right-menu" : ""}>
        {renderView(selectType)}
      </ContainerRigthModal>
    </ContainerItens>
  );
}
