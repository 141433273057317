import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "../../../src/store/modules/account/actions";
import { TablePendingChanges } from "../../components/Table";
import { Container, ContainerBoxes } from "./styles";

import {
  CardHomeEntries,
  CardHomeCategories,
  HomeAccount,
  CardHomePix,
} from "../../components/Cards";

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(AccountActions.ListLimits());
    // dispatch(AccountActions.GetAccount());
    // dispatch(AccountActions.resumeRequest());
    // dispatch(AccountActions.resumeInoutRequest());
    // dispatch(AccountActions.resumeMonthsRequest());
  }, []);

  return (
    <Container>
      <h1>Bem vindo ao sistema gerência</h1>
      <p></p>
      <ContainerBoxes>
        {/* <HomeAccount /> */}
      </ContainerBoxes>
      <p></p>
      <h1>Ultimos clientes cadastrados</h1>
      <p  className="description">Resumo da sua conta como saldo e outros.</p>
      <ContainerBoxes>
        {/* <CardHomeEntries />
        <CardHomeCategories /> */}
        {/* <TablePendingChanges /> */}
      </ContainerBoxes>
    </Container>
  );
}
