import React, { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import {
  Container,
  ContainerInputs,
  ContainerHeader,
  ContainerBody,
  ButtonNext,
  Loading
} from "./styles";

import { HeaderBackImage } from "../../components/Headers";
import { ButtonDefault } from "../../components/Buttons";
import { loginRequest } from "../../store/modules/user/actions";

import { Link } from 'react-router-dom'


const initialValues = {
  username: "",
  password: ""
};

export default function SignIn() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.user.loading);

  function login({ username, password }) {
    dispatch(loginRequest({ username, password }));
  }

  return (
    <Container>
      <section className="container-small">
        <HeaderBackImage onClick={() => history.goBack()} />
        <Formik
          onSubmit={login}
          initialValues={initialValues}
          initialErrors={initialValues}
          initialTouched={initialValues}
        >
          {props => (
            <>
              <ContainerInputs>
                <input
                  type="text"
                  autoCapitalize="none"
                  placeholder="Email"
                  value={props.values.username}
                  onChange={props.handleChange("username")}
                />
                <input
                  type="password"
                  autoCapitalize="none"
                  placeholder="Senha"
                  value={props.values.password}
                  onChange={props.handleChange("password")}
                />
                <Link  to="/forget">Recuperar senha</Link>
              </ContainerInputs>

              <ButtonDefault
                type="submit"
                onClick={props.handleSubmit}
                background={theme.color.blackButton}
                color={theme.color.white}
                title="Entrar"
              />
            </>
          )}
        </Formik>
      </section>
    </Container>
  );
}
