import { all, takeLatest, put, call, select,takeEvery } from 'redux-saga/effects'
import apiPayment from '../../../services/apiPayment'
import * as PayBillActions from './actions'
import * as GeneralActions from '../general/actions'
import moment from 'moment'

export function* payBillsRequest(action) {
  try {
    const { per_page, page } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { token } = yield select((state) => state.user)
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/paybills',
      { per_page, page },
    )
    yield put(PayBillActions.payBillsRequestSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}
export function* payBillCheckRequest(action) {
  try {
    const { digitable_line, callback, carousel } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { token } = yield select((state) => state.user)
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/paybill/boleto/check',
      { bar_code: digitable_line },
    )
    yield put(
      PayBillActions.payBillCheckSuccess({
        digitable_line: data.boleto_object.digitable,
        settleDate: data.boleto_object.settleDate,
        dueDate: moment(data.boleto_object.dueDate).format('DD/MM/YYYY'),
        assignor: data.boleto_object.assignor,
        amount: data.amount,
        have_balance: data.have_balance,
        issuer_name:data.issuer_name,
        payer_name:data.payer_name,
      }),
    )
    if (callback) {
      callback(carousel)
    }
  } catch (error) {
    console.log(error)

    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* newPayBill(action) {
  try {
    const { digitable_line, callback } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { token } = yield select((state) => state.user)
    const { data } = yield call(
      apiPayment.post,
      '/payment/partner/v1/web/paybill/boleto',
      { bar_code: digitable_line },
    )
    yield put(PayBillActions.payBillsRequest({ per_page: 10, page: 1 }))
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}

export function* Get(action) {
  try {
    const { id } = action.payload
    yield put(GeneralActions.setLoading({ loading: true }))
    const { data } = yield call(
        apiPayment.get,
        `/payment/partner/v1/web/paybill/${id}`,
    )
    yield put(PayBillActions.Detail(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
    yield put(GeneralActions.setLoading({ loading: false }))
  }
}


export default all([
  takeLatest('@paybill/REQUEST', payBillsRequest),
  takeLatest('@paybill/REQUEST_CHECK', payBillCheckRequest),
  takeLatest('@paybill/NEW', newPayBill),
  takeEvery('@paybill/PAYBILL_GET', Get),
])
