import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { ButtonDefault } from '../../../components/Buttons'
import { Container, ContainerBox, NoteContainer, OuterBox } from './styles'
import { formatToReal } from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import * as BillingActions from '../../../store/modules/billing/actions'
import Paginator from '../../../components/Paginator'
import DisplayInfos from './DisplayInfo'

import TransactionByCompany from '../../Finance/TransactionByCompany'
import CompanyByPartner from '../CompanyByPartner'

import DisplayInfo2 from './DisplayInfo2'

import * as TransactionActions from '../../../store/modules/transaction/actions'
export default function ClientDetail() {
  const theme = useTheme()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, totalResume } = useSelector((state) => state.myclient)
  let { per_page } = useSelector((state) => state.transaction)

  const account = useSelector((state) => state.account.account)

  useEffect(() => {
    // if (company.id == undefined) {
    //   history.goBack()
    //   return
    // }
    // dispatch(CompanyActions.resumeBilling({ my_client_id: myclient.id }));
    // dispatch(BillingActions.billingsRequest({ per_page: 20, page: 1, query: { my_client_id: myclient.id } }));

    dispatch(
      CompanyActions.CompanyGetDetail({ id: params.id, callback: () => {} }),
    )
  }, [dispatch])

  return (
    <Container>
      <h1>Sub-conta</h1>
      <div className="horizontal">
        <p>Detalhes do novo cliente</p>
      </div>
      <OuterBox>
        <ContainerBox>
          {company.id != undefined && <DisplayInfos item={company} />}
        </ContainerBox>
        {/* <ContainerBox>
          {myclient.id != undefined && <DisplayInfo2 item={myclient} account={account} />}
        </ContainerBox> */}
      </OuterBox>
    <TransactionByCompany client_id={parseInt(params.id)} client_type="customer" />
     
    </Container>
  )
}
