export function CompanyRequest({ per_page, page,query }) {
  return { type: '@company/LIST_REQUEST', payload: { per_page, page,query } }
}

export function CompanyRequestSuccess({ data, page, pages, total }) {
  return {
    type: '@company/LIST_REQUEST_SUCCESS',
    payload: { data, page, pages, total },
  }
}

export function CompanyDetail({ company }) {
  return {
    type: '@company/DEFINE_DETAIL',
    payload: { company },
  }
}

export function CompanyGetDetail({ id, callback }) {
  return {
    type: '@company/GET_DETAIL',
    payload: { id, callback },
  }
}

export function CompanyTransactionsRequestSuccess({
  data,
  page,
  pages,
  total,
}) {
  return {
    type: '@company/TRANSACTIONS_LIST_REQUEST_SUCCESS',
    payload: { data, page, pages, total },
  }
}

export function CompanyTransactionsRequest({ per_page, page,query }) {
  return {
    type: '@company/TRANSACTIONS_LIST_REQUEST',
    payload: { per_page, page,query },
  }
}

export function newCompany({
  name,
  email,
  phone,
  document,
  zip_code,
  address,
  neighborhood,
  city,
  state,
  number,
  complement,
  callback,
}) {
  return {
    type: '@company/NEW',
    payload: {
      name,
      email,
      phone,
      document,
      zip_code,
      address,
      neighborhood,
      city,
      state,
      number,
      complement,
      callback,
    },
  }
}
