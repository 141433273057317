import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../utils/format";
import * as PayBillActions from "../../store/modules/paybill/actions";
import Paginator from "../../components/Paginator";

import PyamentIcon from "../../components/PyamentIcon"



export default function PayBill() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, page, pages, total } = useSelector(
    (state) => state.paybill
  );

  console.log(data);

  useEffect(() => {
    dispatch(PayBillActions.payBillsRequest({ per_page: 3, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(PayBillActions.payBillsRequest({ per_page: 3, page }));
  });

  const cancel = (item) => {

  }

  const handleNewPress = () => {
    history.push("/conveniencia/pagar-conta/novo");
  };

  return (
    <Container>
      <h1>Pagamento de contas</h1>
      <div className="horizontal">
        <p>Lista das contas que você pagou.</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!data.length && <p>nenhum pagamento realizado</p>}
      {!!data.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Valor</th>
                <th>Tipo</th>
                <th>Documento</th>
                <th>Status</th>
                <th>Açoes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((b) => (
                <tr key={b.id} className={`${b.status}`} >
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{formatToReal(b.amount)}</td>
                  <td className="payment-type"><PyamentIcon type={b.type} size={20} textSize={12} /></td>
                  <td>{b.document_payer}</td>
                  <td>{b.status}</td>
                  <td className="actions">
                    <div className="button">
                      <i className="fas fa-exclamation-circle"></i>
                    </div>
                    <div className="button">
                      <i className="fas fa-pen-square"></i>
                    </div>
                    <div className="button" onClick={() => cancel(b)}>
                      <i className="fas fa-trash"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
  );
}
