import React from 'react'
import { Container, ContainerItem, Label, Value } from './styles'
import PropTypes from 'prop-types'
import { formatToReal } from '../../../../utils/format'

function DisplayInfo({ item }) {
  return (
    <Container>
      <ContainerItem className="row">
        <Label>Nome legal</Label>
        <Value>{item.name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Email</Label>
        <Value>{item.email}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Conta interna</Label>
        <Value>{item.account}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Chave pix</Label>
        <Value>{item.pix_key}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Saldo</Label>
        <Value>{formatToReal(parseFloat(item.balance))}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Instituição</Label>
        <Value>{item.bank_name}</Value>
      </ContainerItem>
    </Container>
  )
}

DisplayInfo.propTypes = {
  items: PropTypes.array.isRequired,
}

export default DisplayInfo
