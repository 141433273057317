import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  max-height: 320px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  margin-right: 20px;
  @media (max-width: 609px) {
    margin-right: 0;
  }
`;

export const ContainerSaldo = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 16px;
  }
`;

export const ContainerTop = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Div = styled.div`
  align-items: center;
`;

export const Text = styled.h2`
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  font-size: ${({ font }) => (font ? font : 16)}px;
`;

export const TextMoney = styled.h2`
  font-size: 20px;
  font-weight: 100;
  color: ${({ color }) => color};
`;

export const ContainerList = styled.div`
  flex-direction: row;
  margin-top: 20px;
`;
export const ContainerBarInfo = styled.div`
  margin-right: 10px;
  align-items: center;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;
export const ContainerBarBox = styled.div`
  flex-direction: row;
  justify-content: space-between;
  width: 40px;
`;

export const BarBox = styled.div`
  height: 150px;
  width: 10px;
  background-color: #f4f4f4;
  border-radius: 2px;
  overflow: hidden;
  justify-content: flex-end;
`;

export const BarContent = styled.div`
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => color};
`;
