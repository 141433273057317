import styled, { keyframes } from 'styled-components'

import colors from '../../../styles/colors'

export const ContainerItens = styled.div`
  flex-direction: initial;
  overflow: hidden;
  display: grid;
  padding: 20px;
  overflow-y: scroll;
  @media (max-width: 609px) {
    width: 100%;
    padding-bottom: 100px;
  }
`

export const LabelType = styled.div`
  border-radius: 3.13rem;
  text-align: center;
  min-width: 10ch;
  max-width: 15ch;
  padding: 0.13rem 1rem;
  font-weight: bolder;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 10px;

  &.transfer {
    background-color: rgb(253, 229, 226);
    color: rgb(198, 50, 42);
  }
  &.refund_ted,
  &.refund_pix {
    background-color: #eabc07;
    color: #fff;
  }
  &.ted,
  &.pix {
    background-color: rgb(224, 255, 241);
    color: rgb(0, 139, 87);
  }
`

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`
export const Filter = styled.div`
  max-width: 1000px;
  @media (max-width: 609px) {
    flex-direction: column-reverse;

  }
  .filter_container{
    flex-direction: row;
    @media (max-width: 609px) {
     flex-direction: column;
     .filter_item{
         padding:0;
           button{
            width:100%;
            }
         }
    }
    .filter_item{
        padding: 10px;
    }
  }
  .rang-date{
      flex-direction: row;
      label{
       margin-right:10px
      }
  }


`

export const Content = styled.div`
  flex-direction: row;
  max-width: 1000px;
  @media (max-width: 609px) {
    flex-direction: column-reverse;
    .balance {
      margin-bottom: 20px;
      padding: 0px !important;
    }
  }

  .table {
    flex: 7;
  }
  .balance {
    flex: 3;
    padding: 0 10px 0 10px;

    .content {
      background-color: white;
      padding: 10px;
      border-radius: 12px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      .item {
        padding: 10px;
        .title {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;

          color: #8e8e8e;
        }
        .subtitle {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;

          color: #000000;
        }
      }
    }
  }
`

export const TransactionItem = styled.div`
  background-color: white;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  min-height: 80px;

  .bar-container {
    position: relative;
    width: 10px;
    flex: 1;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }
  }
  .info {
    flex: 5;
    padding: 5px;
    justify-content: center;
    .title {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
    .subtitle {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: uppercase;
      color: #797878;
    }
  }
  .price {
    justify-content: center;
    flex: 3;
    text-align: right;
    padding: 5px 10px 5px 5px;
    flex-direction: column;
    .amount {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      color: #000000;
    }
    .clock {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: uppercase;
      color: #797878;
    }
  }
  .action {
    flex: 1;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const ContainerRigthModal = styled.div`
  /* position: absolute; */
  display: block;
  background: white;
  height: 100vh;
  margin-left: auto;
  width: 300px;
  /* opacity: 0; */
  transform: translateX(100%);
  overflow-x: scroll;
  padding-bottom: 130px;
  position: absolute;
  right: 0;

  @media (max-width: 609px) {
    width: 107%;
    padding-right: 28px;
    right: -30px;
  }

  /* 
  animation-delay: 1s;

 .show-rigth-menu{
    animation-duration: 1s;
    animation-name: animation-scroll-menu;
    animation-timing-function: linear;
  }

  @keyframes animation-scroll-menu{
    0% {transform: translateX(400px); }
    100%{transform: translateX(0px); }
  } */
`

export const LabelRightMenu = styled.h3`
  margin-top: 15px;
  color: #8e8e8e;
  font-family: 'Roboto', sans-serif;
`

export const ContentRightMenu = styled.h3`
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
`

export const HeaderRigthModal = styled.div`
  padding: 10px;
  display: block;
  font-family: 'Roboto', sans-serif;
`

export const CloseIconHeader = styled.img`
  width: 30px;
  margin-left: auto;
  display: block;

  &:hover {
    cursor: pointer;
  }
`

export const RigthModal = styled.div`
  padding: 10px;
  font-family: 'Roboto', sans-serif;
`
export const DateLabel = styled.div`
  padding: 10px;
  font-family: 'Roboto', sans-serif;
`

export const Container = styled.div`

  display: flex;
  flex: 1;
  animation: ${animationName} 0.8s;
  display: grid;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: 'Roboto-Bold', sans-serif;
    color: ${colors.grayText};
  }

  .autentic-p {
    color: #666666;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    margin: 0;
  }

  .autentic-span {
    color: #919090;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
  }

  tr {
    td.payment-type {
      & div {
        width: auto;
      }
    }
    .actions {
      display: table-cell;
      .button {
        width: 33%;
        display: table-cell;
        i {
          font-size: 18px;
        }
      }
    }
  }
  tr.done {
    .button {
      pointer-events: none;
      opacity: 0.2;
    }
  }
`

export const ButtonDownload = styled.a`
 
`

export const ContainerButtons = styled.div`
     .button-pdf{
         width: 250px;
        height: 50px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: none;
        background-color: #272626;
        color: #FFFFFF;
        border-radius: 10px;
        margin-top: 10px;
        -webkit-box-shadow: 0px 2px 12px -5px #000000;
        box-shadow: 0px 2px 12px -5px #000000;
        font-weight: bold;
        font-size: 18px;
  }
  `

export const ContainerDiv = styled.div`

border-bottom: 2px dashed #C2C2C2;

  .header-modal {
    margin: 32px 0;
  }

  img {
    height: 50px;
    width: 200;
    display: flex;
    align-self: center;
  }

  .subcontainer-modal {
    height: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
  }

  .title {
    text-transform: uppercase;
    color: #5B5B5B;
    font-weight: bold;
  }

  .subtitle {
    text-transform: uppercase;
    color: #433F3F;
    font-weight: bold;
    margin: 0;
  }

  .document {
    color: #8F8E8E;
    font-weight: bold;
    margin: 0;
    margin-bottom: 32px;
  }

  .value {
    color: #5B5B5B;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    margin-bottom: 32px;
  }

  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    /* margin-top: 42px; */

    .title-2 {
      color: #5B5B5B;
      font-weight: bold;
      margin: 0;
    }

    .subtitle-2 {
      color: #8F8E8E;
      font-weight: bold;
    margin: 0;

    }
  }
`