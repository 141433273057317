import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import apiPayment from '../../../services/apiPayment'
export default function Transaction() {
  const [rightMenu, setRightMenu] = useState(false)
  const [rightMenuInfo, setRightMenuInfo] = useState([])
  const [selectType, setSelectType] = useState('account')

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, page, pages, per_page, total } = useSelector(
    (state) => state.company,
  )

  const { balance, balance_block } = useSelector((state) => state.account)
  const { transfer } = useSelector((state) => state.transfer)
  const { deposit } = useSelector((state) => state.deposit)
  const account = useSelector((state) => state.account.account)
  const company = useSelector((state) => state.user.company)
  const [transferItens, settransferItens] = useState([])
  const [companySelected, setCompanySelected] = useState({})

  useEffect(() => {
    dispatch(AccountActions.balanceRequest())

  }, [dispatch])

  useEffect(() => {
    dispatch(CompanyActions.CompanyRequest({ per_page: per_page, page: 1 }))
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(
      CompanyActions.CompanyRequest({ per_page: per_page, page: value }),
    )
  })

  const renderView = (type) => {
    return renderTransfer()
  }
  const renderTransfer = () => (
    <>
      <HeaderRigthModal>
        {' '}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false)
          }}
        />{' '}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="deposit">Sub-conta</LabelType>
        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatDate(companySelected.updatedAt)}{' '}
        </ContentRightMenu>
        <LabelRightMenu> Nome: </LabelRightMenu>
        <ContentRightMenu> {companySelected.name} </ContentRightMenu>

        <LabelRightMenu> Email: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {companySelected.email}
        </ContentRightMenu>
        <LabelRightMenu> Conta interna</LabelRightMenu>
        <ContentRightMenu> {companySelected.account} </ContentRightMenu>
        <LabelRightMenu> Saldo: </LabelRightMenu>
        <ContentRightMenu> {formatToReal(parseFloat(companySelected.balance))} </ContentRightMenu>

        <LabelRightMenu> Chave pix: </LabelRightMenu>
        <ContentRightMenu> {companySelected.pix_key} </ContentRightMenu>
        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu> {companySelected.bank_name} </ContentRightMenu>
      </RigthModal>
    </>
  )
  const handleNewPress = () => {
    history.push('/financeiro/transferencia/novo')
  }

  const handleInfoRightMenu = (info) => {
    setSelectType('account')
    setRightMenu(true)
    setRightMenuInfo(info)
    setCompanySelected(info)
    apiPayment
    .get(`/payment/partner/v1/web/customer/balance/${info.id}`)
    .then(({ data }) => {
      setCompanySelected({...info,...data})
    })
    // history.push("/transferencia/novo");
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Sub-contas</h1>
        <div className="horizontal">
          <p>Lista de sub-contas</p>
          <ButtonDefault
            type="button"
            title="Novo"
            background={theme.color.green}
            color={theme.color.white}
            width="250px"
            height="50px"
            onClick={handleNewPress}
          />
        </div>

        <Content>
          <div className="table">
            {!data.length && <p>nenhuma transferência realizada</p>}
            {!!data.length && (
              <>
                {data.map((item, index) => (
                  <>
                    <TransactionItem key={index}>
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: theme.color['in'] || '#fff',
                            }}
                          ></div>
                        </div>
                        <div className="info">
                          <span className="title">{item.name}</span>
                          <span className="subtitle"> {item.email}</span>
                        </div>
                        <div className={`price`}>
                          {' '}
                         
                          <span className="clock">
                            {' '}
                            {formatDate(item.updatedAt)}{' '}
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => {
                            handleInfoRightMenu(item)
                          }}
                        >
                          <i className="material-icons icon">navigate_next</i>
                        </div>
                      </TransactionItem>
                  </>
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
        </Content>
      </Container>

      <ContainerRigthModal className={rightMenu ? 'show-right-menu' : ''}>
        {renderView(selectType)}
      </ContainerRigthModal>
    </ContainerItens>
  )
}
