export function EmployeesRequest({ per_page, page }) {
  return { type: "@employee/LIST_REQUEST", payload: { per_page, page } };
}

export function EmployeesRequestSuccess({ data, page, pages, total }) {
  return {
    type: "@employee/LIST_REQUEST_SUCCESS",
    payload: { data, page, pages, total },
  };
}


export function EmployeeDetail({ employee }) {
  console.log(employee);
  return {
    type: "@employee/DEFINE_DETAIL",
    payload: { employee },
  };
}




export function newEmployee({
  name,
  email,
  phone,
  document,
  zip_code,
  address,
  neighborhood,
  city,
  state,
  number,
  complement,
  callback,
}) {
  return {
    type: "@employee/NEW",
    payload: {
      name,
      email,
      phone,
      document,
      zip_code,
      address,
      neighborhood,
      city,
      state,
      number,
      complement,
      callback,
    },
  };
}