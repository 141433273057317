import React from 'react'
import { Container, ContainerItem, Label, Value } from './styles'
import PropTypes from 'prop-types'
import { formatToReal } from '../../../../utils/format'

function DisplayInfo({ item, account }) {
  return (
    <Container>
      <ContainerItem className="row">
        <Label>Porte</Label>
        <Value>{item.size}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Natureza Jurídica</Label>
        <Value>{item.legal_nature}</Value>
      </ContainerItem>
      {item.main_activity != '' && (
        <ContainerItem className="row">
          <Label>Atividade principal</Label>
          <Value>
            {JSON.parse(item.main_activity).code} -{' '}
            {JSON.parse(item.main_activity).text}
          </Value>
        </ContainerItem>
      )}
      
      {item.partners != '' && (
        <ContainerItem className="row">
          <Label>Sócios</Label>
          {JSON.parse(item.partners).map((itemS) => (
            <>
              <Value>
                {itemS.nome} - {itemS.qual} -{' '}
              </Value>
              <br></br>
            </>
          ))}
        </ContainerItem>
      )}
      <ContainerItem className="row">
        <Label>Conta:</Label>
        <Value>{account.account}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Agência:</Label>
        <Value>{account.branch}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>banco:</Label>
        <Value>
          {account.bank_name} - {account.brank_number}
        </Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Chave pix:</Label>
        <Value>{account.pix_key}</Value>
      </ContainerItem>
    </Container>
  )
}

DisplayInfo.propTypes = {
  items: PropTypes.array.isRequired,
}

export default DisplayInfo
