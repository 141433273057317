export function MyClientsRequest({ per_page, page }) {
  return { type: '@myclient/LIST_REQUEST', payload: { per_page, page } }
}

export function MyClientsRequestSuccess({ data, page, pages, total }) {
  return {
    type: '@myclient/LIST_REQUEST_SUCCESS',
    payload: { data, page, pages, total },
  }
}

export function MyClientDetail({ myclient }) {
  return {
    type: '@myclient/DEFINE_DETAIL',
    payload: { myclient },
  }
}

export function CompanyDetail({ company }) {
  return {
    type: '@company/DEFINE_DETAIL',
    payload: { company },
  }
}

export function newMyClient({
  fantasy_name,
  legal_name,
  user_email,
  user_name,
  user_document,
  document,
  zip_code,
  address,
  neighborhood,
  city,
  state,
  number,
  complement,
  partners,
  main_activity,
  secondary_activities,
  legal_nature,
  size,
  callback,
}) {
  return {
    type: '@myclient/NEW',
    payload: {
      fantasy_name,
      legal_name,
      user_email,
      user_name,
      user_document,
      document,
      zip_code,
      address,
      neighborhood,
      city,
      state,
      number,
      complement,
      partners,
      main_activity,
      legal_nature,
      size,
      secondary_activities,
      callback,
    },
  }
}

export function billingResumeSuccess({ totalResume }) {
  return {
    type: '@myclient/RESUME_BILLING_SUCCESS',
    payload: { totalResume },
  }
}

export function resumeBilling({ callback, my_client_id }) {
  return {
    type: '@myclient/RESUME_BILLING',
    payload: {
      callback,
      my_client_id,
    },
  }
}

export function MyClientGetDetail({ id, callback }) {
  return {
    type: '@myclient/GET_DETAIL',
    payload: { id, callback },
  }
}
