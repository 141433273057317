const ErrorPTBR = {
  '10007': 'token inválido',
  '10008': 'usuário ou senha inválidos',
  '10013': 'token inválido',
  '10200': "Usuário e senha inválido"
}
const errors = {
  PT_BR: ErrorPTBR,
}

const getError = (language, code) => {
  if (errors[language][code] === undefined) {
    return 'Algum problema aconteceu'
  } else {
    return errors[language][code]
  }
}

export default getError
