import styled, { keyframes } from "styled-components";

import colors from "../../../styles/colors";

const animationName = keyframes`
  0% {       
    opacity: 0;
    transform: translateX(300px); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }
`;

export const ContainerBoxContent = styled.div`
    width:100%;
    display: inline-block;
`;


export const NoteContainer = styled.div`
  position: absolute;
  right: 41px;
  width: 169px;
  height: 173px;
  text-align: center;
  background-color: #FF5555;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 103px;
  div{
    font-size:60px;
    color:#FFFFFF;
  }

`


export const OuterBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 609px) {
    flex-direction: column;
  }
`;


export const ContainerBox = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 381px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 5px 5px 32px -15px #000000;
  box-shadow: 5px 5px 32px -15px #000000;
  max-width: 410px;
  width: 100%;
  span {
    font-size: 10px;
    position: absolute;
    bottom: -15px;
  }
  input {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    :focus {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
    }
  }
  .fieldNoMargin {
    min-width: 40%;
    padding: 0px !important;
  }
  .horizontal {
    width: 100%;
    margin-bottom: 0px !important;
    align-items: flex-end !important;
    justify-content: space-between;
  }
  .horizontal-flex-start {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    input {
      margin: 0px !important;
      padding: 0px;
    }
    label {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 3px;
    }
  }
  .container-buttons {
    margin-top: 20px;
  }

  .container-success {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 20px;
      text-align: center;
      margin-top: 20%;
    }
    button {
      margin: 0px;
    }
  }
  .rec.rec-swipable-null {
    justify-content: center;
  }
  .container-icon-success{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    & .content{
      background:#16BF57;
      width: 95px;
      height: 95px;
      display: flex;
     justify-content: center;
      align-items: center;
      border-radius: 53px;
      i{
        color: white;
      }
    }
    & .title{
      font-size:32px;
      text-align:center;
      margin-top:20px
    }
  }
`;


export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 900px;
  padding: 20px;
  animation: ${animationName} 0.8s;

  .horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    font-family: "Roboto-Bold", sans-serif;
    color: ${colors.grayText};
  }
  p {
    font-weight: 100;
  }
`;
