import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  ContainerItens,
  Container,
  TransactionItem,
  Content,
  ContainerRigthModal,
  LabelRightMenu,
  ContentRightMenu,
  HeaderRigthModal,
  CloseIconHeader,
  RigthModal,
  LabelType,
  DateLabel,
  ButtonDownload,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import * as CompanyActions from '../../../store/modules/company/actions'
import { ButtonDefault } from '../../../components/Buttons'
import Pagination from '@material-ui/lab/Pagination'
import * as AccountActions from '../../../store/modules/account/actions'
import * as MyClientActions from '../../../store/modules/myclient/actions'
import '../../../assets/animations/animations.css'
import CloseIcon from '../../../assets/images/close.png'
import apiPayment from '../../../services/apiPayment'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Item from "./item";

const MySwal = withReactContent(Swal)

export default function Transaction({ client_id, client_type }) {
  const [rightMenu, setRightMenu] = useState(false)
  const [rightMenuInfo, setRightMenuInfo] = useState([])
  const [selectType, setSelectType] = useState('account')

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, page, pages, per_page, total } = useSelector(
    (state) => state.company,
  )

  const { balance, balance_block } = useSelector((state) => state.account)
  const { transfer } = useSelector((state) => state.transfer)
  const { deposit } = useSelector((state) => state.deposit)
  const account = useSelector((state) => state.account.account)
  const company = useSelector((state) => state.user.company)
  const [transferItens, settransferItens] = useState([])
  const [companySelected, setCompanySelected] = useState({})

  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  useEffect(() => {
    dispatch(
      CompanyActions.CompanyRequest({
        per_page: per_page,
        page: 1,
        query: { partener_id:client_id},
      }),
    )
  }, [dispatch])

  const handlePagePress = useCallback((event, value) => {
    dispatch(
      CompanyActions.CompanyRequest({
        per_page: per_page,
        page: value,
        query: { client_id,client_type },
      }),
    )
  })

  const handleDetailPress = (company) => {
    dispatch(MyClientActions.CompanyDetail({ company }))

    history.push(`/crm/sub-conta/detalhe/${company.id}`)
  }

  const renderView = (type) => {
    return renderTransfer()
  }
  const renderTransfer = () => (
    <>
      <HeaderRigthModal>
        {' '}
        <CloseIconHeader
          src={CloseIcon}
          alt="close menu"
          onClick={() => {
            setRightMenu(false)
          }}
        />{' '}
      </HeaderRigthModal>
      <RigthModal>
        <h1> Detalhes </h1>
        <LabelType className="deposit">Sub-conta</LabelType>
        <LabelRightMenu> Data: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatDate(companySelected.updatedAt)}{' '}
        </ContentRightMenu>
        <LabelRightMenu> Nome: </LabelRightMenu>
        <ContentRightMenu> {companySelected.name} </ContentRightMenu>

        <LabelRightMenu> Email: </LabelRightMenu>
        <ContentRightMenu> {companySelected.email}</ContentRightMenu>
        <LabelRightMenu> Conta interna</LabelRightMenu>
        <ContentRightMenu> {companySelected.account} </ContentRightMenu>
        <LabelRightMenu> Saldo: </LabelRightMenu>
        <ContentRightMenu>
          {' '}
          {formatToReal(parseFloat(companySelected.balance))}{' '}
        </ContentRightMenu>

        <LabelRightMenu> Chave pix: </LabelRightMenu>
        <ContentRightMenu> {companySelected.pix_key} </ContentRightMenu>
        <LabelRightMenu> Instituição: </LabelRightMenu>
        <ContentRightMenu> {companySelected.bank_name} </ContentRightMenu>
      </RigthModal>
    </>
  )
  const handleNewPress = () => {
    history.push('/financeiro/transferencia/novo')
  }

  const handleInfoRightMenu = (info) => {
    setOpen(true)
    setCompanySelected(info)
    apiPayment
      .get(`/payment/manager/v1/web/balance/${info.id}/customer`)
      .then(({ data }) => {
        setCompanySelected({ ...info, ...data })
      })
    // history.push("/transferencia/novo");
  }

  const handleDelete = (company) => {
    MySwal.fire({
      title: `<p>Deseja deletar a sub-conta ${company.name}?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return apiPayment
          .get(`/payment/manager/v1/web/account/delete/${company.id}`)
          .then((response) => {
            setOpen(false)
            dispatch(
              CompanyActions.CompanyRequest({
                per_page: per_page,
                page: 1,
                query: { client_id },
              }),
            )
            return response.json()
          })
          .catch((error) => {})
      }
    })
  }

  return (
    <ContainerItens>
      <Container>
        <h1>Sub-contas</h1>
        <div className="horizontal">
          <p>Lista de sub-contas</p>
        </div>

        <Content>
          <div className="table">
            {!data.length && <p>nenhuma transferência realizada</p>}
            {!!data.length && (
              <>
                {data.map((item, index) => (
                  <Item item={item} index={index} handleInfoRightMenu={handleInfoRightMenu} />
                ))}
                <Pagination
                  count={pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handlePagePress}
                />
              </>
            )}
          </div>
        </Content>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1>Sub-conta </h1>
          <Typography id="modal-modal-title" sx={{ mt: 2 }}>
            Nome: {companySelected.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Email:{companySelected.email}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Conta interna:{companySelected.account}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Saldo: {formatToReal(parseFloat(companySelected.balance))}{' '}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Chave pix: {companySelected.pix_key}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Instituição: {companySelected.bank_name}
          </Typography>
          <Box>
            <ButtonDefault
              type="button"
              title="Deletar"
              background={theme.color.green}
              color={theme.color.white}
              width="250px"
              height="50px"
              onClick={() => handleDelete(companySelected)}
            />

            <ButtonDefault
              type="button"
              title="Detalhe"
              background={theme.color.green}
              color={theme.color.white}
              width="250px"
              height="50px"
              onClick={() => handleDetailPress(companySelected)}
            />
          </Box>
        </Box>
      </Modal>
    </ContainerItens>
  )
}
