import React from "react";
import { Container, ContainerItem, Label, Value } from "./styles";
import PropTypes from "prop-types";
import { formatToReal } from "../../utils/format";

function DisplayInfo({
  items,
}) {
  return (
    <Container>
      {items.map((item, key) => {
          switch (item.type) {
            case "password":
              return (
                <ContainerItem key={key} className={item.position}>
                  <Label>{item.name}</Label>
                  <Value> { '*'.repeat(item.value.length)}</Value>
                </ContainerItem>
              )
            case "render":
              return (
                <ContainerItem key={key} className={item.position}>
                  <Label>{item.name}</Label>
                  <Value> {item.render(item.value)}</Value>
                </ContainerItem>
              )
            case "money":
              return (
                <ContainerItem key={key} className={item.position}>
                  <Label>{item.name}</Label>
                  <Value> {formatToReal(item.value)}</Value>
                </ContainerItem>
              )
            default:
              return (
                <ContainerItem key={key} className={item.position}>
                  <Label>{item.name}</Label>
                  <Value>{item.value != "" ? item.value : " - "}</Value>
                </ContainerItem>
              )
          }
      }
      )}
    </Container>
  );
}

DisplayInfo.propTypes = {
  items: PropTypes.array.isRequired,
};

export default DisplayInfo;
