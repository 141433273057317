import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import apiPayment from '../../../services/apiPayment'
import * as GeneralActions from '../general/actions'
import {
  requestBalanceSucesss,
  resumeRequestSucesss,
  resumeInoutSuccess,
  resumeMonthsSuccess,
  ListLimitsSucesss,
  GetAccountSuccess,
} from './actions'

export function* getBalance(action) {
  try {
    const { client_id, client_type } = action.payload
    const { data } = yield call(
      apiPayment.get,
      `/payment/manager/v1/web/balance/${client_id}/${client_type}`,
    )
    yield put(requestBalanceSucesss(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* resumeTransactions() {
  try {
    const response = yield call(
      apiPayment.get,
      '/payment/partner/v1/web/transactions/resume',
    )
    const data = response.data
    yield put(resumeRequestSucesss(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* resumeInoutRequest() {
  try {
    const response = yield call(
      apiPayment.get,
      '/payment/partner/v1/web/transactions/resume/inout',
    )
    const data = response.data
    yield put(resumeInoutSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* GetAccount() {
  try {
    const response = yield call(
      apiPayment.get,
      '/payment/partner/v1/web/account',
    )
    const data = response.data
    yield put(GetAccountSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* ListLimits() {
  try {
    const response = yield call(
      apiPayment.get,
      '/payment/partner/v1/web/transaction/limits',
    )
    const data = response.data
    yield put(ListLimitsSucesss(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export function* resumeMonthsRequest() {
  try {
    const response = yield call(
      apiPayment.get,
      '/payment/partner/v1/web/transactions/resume/months',
    )
    const data = response.data
    yield put(resumeMonthsSuccess(data))
  } catch (error) {
    yield put(GeneralActions.checkErrorAndResolve({ error }))
  } finally {
  }
}

export default all([
  takeLatest('@account/BALANCE_REQUEST', getBalance),
  takeLatest('@account/RESUME_REQUEST', resumeTransactions),
  takeLatest('@account/RESUME_IN_OUT_REQUEST', resumeInoutRequest),
  takeLatest('@account/RESUME_MONTHS_REQUEST', resumeMonthsRequest),
  takeLatest('@account/LIST_LIMIT', ListLimits),
  takeLatest('@account/GET_ACCOUNT', GetAccount),
])
