import React, { useState, useEffect, CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ContainerPage, ContainerPagination } from './styles'
import { ButtonDefault } from '../../Buttons'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as MyClientActions from '../../../store/modules/myclient/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { InputMask } from '../../Inputs'
import { cpfIsValid } from '../../../utils/check'
import Carousel from 'react-elastic-carousel'
import apiCep from '../../../services/apiCEP.js'
import apiCNPJ from '../../../services/apiCNPJ.js'
import * as GeneralActions from '../../../store/modules/general/actions'

import DisplayInfos from './DisplayInfo'

const renderPagination = (
  { pages, activePage, onClick },
  props,
  handleNextPage,
  success,
) => {
  return (
    <ContainerPagination>
      {pages.map((page) => {
        const isActivePage = activePage === page
        return (
          <li
            className={`dot ${isActivePage ? 'selected' : ''} ${
              success ? 'success' : ''
            }`}
            key={page}
            onClick={() => {
              if (!success) handleNextPage(props)
            }}
          />
        )
      })}
    </ContainerPagination>
  )
}

const userValidSchema = Yup.object().shape({
  user_email: Yup.string().required('campo obrigatório'),
  user_name: Yup.string().required('campo obrigatório'),
  user_document: Yup.string().required('campo obrigatório'),
})

const clientValidSchema = Yup.object().shape({
  fantasy_name: Yup.string().required('campo obrigatório'),
  legal_name: Yup.string().required('campo obrigatório'),
  document: Yup.string().required('campo obrigatório'),
})

const AddressValidSchema = Yup.object().shape({
  zip_code: Yup.string()
    .min(9, 'minimo de 8 caracteres')
    .required('campo obrigatório')
    .test('valid', 'cep inválido', (value = '0') => {
      const cep = value.replace(/[\_^\-^\/]/gi, '')
      if (cep && cep.length === 8) {
        return true
      }
      return false
    }),
  address: Yup.string().required('campo obrigatório'),
  neighborhood: Yup.string().required('campo obrigatório'),
  city: Yup.string().required('campo obrigatório'),
  state: Yup.string()
    .min(2, 'deve ter dois digitos')
    .required('campo obrigatório'),
  number: Yup.string().required('campo obrigatório'),
})

const ClientSchema = Yup.object()
  .concat(clientValidSchema)
  .concat(AddressValidSchema)
  .concat(userValidSchema)

const initialValues = {
  fantasy_name: '',
  legal_name: '',
  user_email: '',
  user_name: '',
  user_email: '',
  document: '',
  zip_code: '',
  address: '',
  neighborhood: '',
  city: '',
  state: '',
  number: '',
  complement: '',
  partner_ref: '',
  main_activity: '',
  secondary_activities: '',
  legal_nature: '',
  size: '',
}

const initialErrors = {
  fantasy_name: '-',
  legal_name: '-',
  user_email: '-',
  user_name: '-',
  user_document: '-',
  document: '-',
  zip_code: '-',
  address: '-',
  neighborhood: '-',
  city: '-',
  state: '-',
  number: '-',
  complement: '-',
  size: '',
}

const diplayValues = [
  { key: 'fantasy_name', name: 'Nome fantasia', value: '', position: 'row' },
  { key: 'legal_name', name: 'Nome legal', value: '', position: 'row' },
  { key: 'document', name: 'Cnpj', value: '', position: 'row' },
  { key: 'user_email', name: 'Email do usuário', value: '', position: 'row' },
  { key: 'user_name', name: 'Nome do usuário', value: '', position: 'row' },
  {
    key: 'user_document',
    name: 'Document do usuário',
    value: '',
    position: 'row',
  },
  { key: 'zip_code', name: 'Cep', value: '', position: 'row' },
  { key: 'address', name: 'Logradouro', value: '', position: 'column' },
  { key: 'neighborhood', name: 'Bairro', value: '', position: 'column' },
  { key: 'city', name: 'Cidade', value: '', position: 'column' },
  { key: 'state', name: 'Estado', value: '', position: 'column' },
  { key: 'number', name: 'Número', value: '', position: 'column' },
  { key: 'complement', name: 'Complemento', value: '', position: 'column' },
]

const MyClientCreate = ({ navigation }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [position, setPosition] = useState(0)

  var carousel = null

  const [success, setSuccess] = useState(false)

  useEffect(() => {}, [])

  const handleCnpj = (props) => {
    const { document } = props.values
    let document_formated = document.replace(/\D/g, '')
    if (document_formated.length < 14) {
      dispatch(GeneralActions.setError({ error: 'CNPJ inválido' }))
      return
    }
    dispatch(GeneralActions.setLoading({ loading: true }))
    apiCNPJ
      .checkCNPJ(document_formated)
      .then((result) => {

        props.setFieldValue(
          'address',
          `${result.logradouro}`,
        )
        props.setFieldTouched('address', true)
        props.setFieldValue('neighborhood', result.bairro)
        props.setFieldTouched('neighborhood', true)
        props.setFieldValue('city', result.municipio)
        props.setFieldTouched('city', true)
        props.setFieldValue('state', result.uf)
        props.setFieldTouched('state', true)
        props.setFieldValue('complement', result.complemento)
        props.setFieldTouched('complement', true)
        props.setFieldValue('zip_code', result.cep)
        props.setFieldTouched('zip_code', true)

        props.setFieldValue('legal_name', result.nome)
        props.setFieldTouched('legal_name', true)

        props.setFieldValue('legal_nature', result.natureza_juridica)
        props.setFieldTouched('legal_nature', true)
        props.setFieldValue('size', result.porte)
        props.setFieldTouched('size', true)
        props.setFieldValue('number', result.numero)
        props.setFieldTouched('number', true)
        props.setFieldValue(
          'fantasy_name',
          result.fantasia,
        )
        props.setFieldValue('partners', result.qsa)
        props.setFieldValue(
          'main_activity',
          result.atividade_principal,
        )
        props.setFieldValue(
          'secondary_activities',
          result.atividades_secundarias,
        )
        carousel.goTo(position + 1)
        setPosition(position + 1)
      })
      .catch((error) => {
        if (error.response) {
          dispatch(GeneralActions.setError('CNPJ inválido, tente outro'))
        }
      })
      .finally(() => {
        dispatch(GeneralActions.setLoading({ loading: false }))
      })
  }

  var listRef = null

  const handleCancel = () => {
    history.push('/crm/cliente')
  }

  const handlePrevPage = (props) => {
    carousel.goTo(position - 1)
    setPosition(position - 1)
  }
  const handleNextPage = (props) => {
    switch (position) {
      case 0:
        handleCnpj(props)
        break
      case 1:
        const { legal_name, fantasy_name, document } = props.values
        props.validateForm().then((validation) => props.setTouched(validation))
        clientValidSchema
          .isValid({ legal_name, fantasy_name, document })
          .then((valid) => {
            if (valid) {
              carousel.goTo(position + 1)
              setPosition(position + 1)
              return
            }
            dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
          })
        break
      case 2:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
      case 3:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
      case 4:
        const { user_name, user_email, user_document } = props.values
        props.validateForm().then((validation) => props.setTouched(validation))
        userValidSchema
          .isValid({ user_name, user_email, user_document })
          .then((valid) => {
            if (valid) {
              carousel.goTo(position + 1)
              setPosition(position + 1)
              return
            }
            dispatch(GeneralActions.setError({ error: 'Dados inválidos' }))
          })
        break
      case 5:
        handleSubmitForm(props.values)
        break
      default:
        carousel.goTo(position + 1)
        setPosition(position + 1)
        break
    }
  }

  const handleSubmitForm = (values) => {
    const document = values.document.replace(/[\.^\-^\/]/gi, '')
    dispatch(
      MyClientActions.newMyClient({
        ...values,
        partners: JSON.stringify(values.partners),
        main_activity: JSON.stringify(values.main_activity),
        secondary_activities: JSON.stringify(values.secondary_activities),
        document,
        callback: () => {
          setSuccess(true)
        },
      }),
    )
  }

  if (success) {
    return (
      <Container>
        <div className="container-success">
          <div className="container-icon-success">
            <div className="content">
              <i className="fa fa-check fa-2x"></i>
            </div>
            <div className="title">Cliente criado com sucesso</div>
          </div>
          <ButtonDefault
            title="voltar"
            background={theme.color.green}
            color={theme.color.white}
            width="170px;"
            onClick={handleCancel}
          />
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ClientSchema}
        onSubmit={handleSubmitForm}
        initialErrors={initialErrors}
      >
        {(props) => (
          <>
            <Carousel
              showArrows={false}
              ref={(ref) => (carousel = ref)}
              renderPagination={(renderPropos) => {
                return renderPagination(
                  renderPropos,
                  props,
                  handlePrevPage,
                  success,
                )
              }}
            >
              <ContainerPage>
                <div className="field">
                  <InputMask
                    format="cnpj"
                    type="text"
                    name="document"
                    placeholder="Cnpj da empresa"
                    value={props.values.document}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></InputMask>
                  <span>{props.touched.document && props.errors.document}</span>
                  não se procupe vamos buscar os dados do cnpj
                </div>
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="fantasy_name"
                    placeholder="Nome fantasia"
                    value={props.values.fantasy_name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.fantasy_name && props.errors.fantasy_name}
                  </span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="legal_name"
                    placeholder="Razão social"
                    value={props.values.legal_name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.legal_name && props.errors.legal_name}
                  </span>
                </div>
              </ContainerPage>
              <ContainerPage>
                <InputMask
                  format="cep"
                  id="zip_code"
                  type="text"
                  name="zip_code"
                  autoCapitalize="none"
                  placeholder="Cep"
                  value={props.values.zip_code}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <span>{props.touched.zip_code && props.errors.zip_code}</span>
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="address"
                    placeholder="Logradouro"
                    value={props.values.address}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.address && props.errors.address}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="neighborhood"
                    placeholder="Bairro"
                    value={props.values.neighborhood}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.neighborhood && props.errors.neighborhood}
                  </span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="city"
                    placeholder="Cidade"
                    value={props.values.city}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.city && props.errors.city}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="state"
                    placeholder="Estado"
                    value={props.values.state}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.state && props.errors.state}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="number"
                    placeholder="Numero"
                    value={props.values.number}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>{props.touched.number && props.errors.number}</span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="complement"
                    placeholder="Complemento"
                    value={props.values.complement}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.complement && props.errors.complement}
                  </span>
                </div>
              </ContainerPage>
              <ContainerPage>
                <div className="field">
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Nome do usuário"
                    value={props.values.user_name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.user_name && props.errors.user_name}
                  </span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="user_email"
                    placeholder="Email do usuário"
                    value={props.values.user_email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></input>
                  <span>
                    {props.touched.user_email && props.errors.user_email}
                  </span>
                </div>
                <div className="field">
                  <InputMask
                    format="cpf"
                    type="text"
                    name="user_document"
                    placeholder="Cpf do usuário"
                    value={props.values.user_document}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  ></InputMask>
                  <span>
                    {props.touched.user_document && props.errors.user_document}
                  </span>
                </div>
                <div className="field">
                  <input
                    type="text"
                    placeholder="Assim que a conta for aprovada a senha sera enviado por e-mail"
                    disabled
                  ></input>
                </div>
              </ContainerPage>
              <ContainerPage>
                {position == 5 && <DisplayInfos item={props.values} />}
              </ContainerPage>
            </Carousel>
            <div className="container-buttons">
              <div className="horizontal">
                <ButtonDefault
                  title="Cancelar"
                  background={theme.color.white}
                  color={theme.color.blackButton}
                  width="170px;"
                  onClick={handleCancel}
                />
                <ButtonDefault
                  title="Próximo"
                  background={theme.color.blackButton}
                  color={theme.color.white}
                  width="170px;"
                  onClick={() => handleNextPage(props, handleSubmitForm)}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default MyClientCreate
