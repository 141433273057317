export function setLogged(logged) {
  return {
    type: '@user/SET_LOGGED',
    payload: { logged },
  }
}
export function setToken(token) {
  return {
    type: '@user/SET_TOKEN',
    payload: { token },
  }
}

export function loginRequest({ username, password }) {
  return {
    type: '@user/LOGIN_REQUEST',
    payload: { username, password },
  }
}

export function loginSuccess({ user, token, company }) {
  return {
    type: '@user/LOGIN_SUCCESS',
    payload: { user, token, company },
  }
}

export function ResetPassword({ email, callback }) {
  return {
    type: '@user/RESET_PASSWORD',
    payload: { email, callback },
  }
}
export function ChangePassword({  password, new_password, callback }) {
  return {
    type: '@user/CHANGE_PASSWORD',
    payload: {  password, new_password, callback },
  }
}

export function loginFail() {
  return {
    type: '@user/LOGIN_FAIL',
  }
}

export function logout() {
  return { type: '@user/LOGOUT' }
}

export function GetInfo() {
  return { type: '@user/GET_INFO' }
}

export function GetInfoSuccess({ user, token, company }) {
  return {
    type: '@user/GET_INFO_SUCCESS',
    payload: { user, token, company },
  }
}
