import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
// import Container from 'react-bootstrap/Container';
import * as Yup from "yup";

import {ButtonDefault} from "../../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import {useSelector} from "react-redux";

import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    Image,
} from '@react-pdf/renderer'

import {formatCnpj, formatDate, formatDateHour, formatToReal} from "../../../../utils/format";
import {ButtonDownload, Container, ContainerDiv} from "../styles";
import logoIcon from "../../../../assets/images/packman-white.png";
import LogoPaggo from '../../../../assets/images/genesis-logo.png'
import Typography from "@mui/material/Typography";

const buyValidSchema = Yup.object().shape({});

const initialValues = {
    amount: "0", currency: ""
};


const initialErrors = {
    amount: "-", currency: "-"
};


const diplayValues = [{key: 'balance_before', name: 'Saldo atual', value: '', position: 'row'}, {
    key: 'quantity_formated',
    name: 'Quantidade',
    value: '',
    position: 'row'
}, {key: 'price', name: 'Preço', value: '', position: 'row'}, {
    key: 'total',
    name: 'Total',
    value: '',
    position: 'row'
}, {key: 'balance_after', name: 'Saldo após', value: '', position: 'row'}]


const TransferModal = ({visible, onClose}) => {
    const [position, setPosition] = useState(0);
    var carousel = null;
    const theme = useTheme();



    const account = useSelector((state) => state.account.account)
    const {transfer} = useSelector((state) => state.transfer)

    const { myclient} = useSelector((state) => state.myclient)

    
    const DocumentTransfer = ({transfer, account}) => {
        return (
            <Document>
                <Page
                    size="A4"
                    style={{paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35}}
                >
                    <View>
                        <Image
                            style={{
                                height: 50,
                                width: 200,
                            }} 
                            src={LogoPaggo}
                        />
                    </View>
                    <Text style={{fontSize: 24, textAlign: 'left', marginTop: 12}}>
                        Comprovante de Transferência
                    </Text>
                    <Text style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}>
                        {' '}
                        Valor:{' '}
                    </Text>
                    <Text style={{marginTop: 4, fontSize: 10}}>
                        {' '}
                        {formatToReal(parseFloat(transfer.amount))}{' '}
                    </Text>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Conta Origem:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    De:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {myclient.name}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Documento:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {formatCnpj(account.document)}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Instituição:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {account.bank_name}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Conta Destino:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    Para:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.name}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Documento:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.document}{' '}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Instituição:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.bank_name}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View
                            style={{borderBottom: '1px dashed black', paddingVertical: 4}}
                        >
                            <Text style={{marginTop: 12, fontWeight: '400', fontSize: 18}}>
                                {' '}
                                Informações:{' '}
                            </Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    Tipo:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.type}{' '}
                                </Text>
                            </View>
                            <View style={{marginLeft: 10}}>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    Data:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {formatDateHour(transfer.updatedAt)}
                                </Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexWrap: 'wrap'}}>
                            <View>
                                <Text
                                    style={{marginTop: 12, fontWeight: 'bold', fontSize: 12}}
                                >
                                    {' '}
                                    ID da transação:{' '}
                                </Text>
                                <Text style={{marginTop: 4, fontSize: 10}}>
                                    {' '}
                                    {transfer.integration_id}{' '}
                                </Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }


    const handleCancel = () => {
        onClose()
    };

    const handleNextPagePosition = () => {
        carousel.goTo(position + 1);
        setPosition(position + 1);
    }

    const typeTransactionsRender = (type, operation_type) => {
        switch (type){
            case"ted-internal":
                return "Ted entre contas"
            case"ted":
                return "Transferência ted"
            case"pix":
                return "Transferência pix"
            case"internal":
                return "Transferência para sub-conta"
            case"crypto":
                return "Compra de Stablecoin"
        }
    }

    return (<Modal show={visible} onHide={onClose} size="md" centered>
    
        <Modal.Body>
            <Container>
                <ContainerDiv>
                    <div className='header-modal'>
                <img
                            // style={{
                            //     height: 50,
                            //     width: 200,
                            // }}
                            src={LogoPaggo}
                        />
                        </div>
                        </ContainerDiv>

                <ContainerDiv>
                    <div className='subcontainer-modal'>
                        <p className='title'>PAGADOR</p>
                        <p className='subtitle'>{myclient.name}</p>
                        <p className='document'>{formatCnpj(account.document)}</p>

                        <div className='info'>
                        <p className='title-2'>Banco</p>
                        <p className='subtitle-2'>{account.brank_number} - {account.bank_name}</p>
                    </div>

                    <div className='info'>
                        <p className='title-2'>Agência</p>
                        <p className='subtitle-2'>{account.branch}</p>
                    </div>

                    <div className='info'>
                        <p className='title-2'>Conta</p>
                        <p className='subtitle-2'>{account.number}</p>
                    </div>

                    </div>
                </ContainerDiv>

                <ContainerDiv>
                 
                    <div className='subcontainer-modal'>
                    <p className='title'>BENEFICIÁRIO</p>
                    <p className='subtitle'>{transfer.name}</p>
                    <p className='value'>{formatToReal(parseFloat(transfer.amount))}</p>

                    <div className='info'>
                        <p className='title-2'>Tipo</p>
                        <p className='subtitle-2'>{transfer.type}</p>
                    </div>

                    {transfer.type === 'ted' || transfer.type === 'ted-internal' && (
                        <>
                        <div className='info'>
                          <p className='title-2'>Banco</p>
                          <p className='subtitle-2'>{transfer.bank_name} -{transfer.bank_number} </p>
                      </div>
  
                      <div className='info'>
                          <p className='title-2'>Agência</p>
                          <p className='subtitle-2'>{transfer.branch}</p>
                      </div>
  
                      <div className='info'>
                          <p className='title-2'>Conta</p>
                          <p className='subtitle-2'>{transfer.account}</p>
                      </div>
  
                      <div className='info'>
                          <p className='title-2'>Descrição</p>
                          <p className='subtitle-2'>{''}</p>
                      </div>
                      </>
                    )}
                    
                    {transfer.type === 'pix' && (  
                      <>
                          <div className='info'>
                          <p className='title-2'>Banco</p>
                          <p className='subtitle-2'>{transfer.bank_name.slice(0,transfer.bank_name.lastIndexOf("–") )}</p>
                      </div>
                    <div className='info'>
                        <p className='title-2'>Chave Pix</p>
                        <p className='subtitle-2'>{transfer.pix_key}</p>
                    </div>

                    <div className='info'>
                        <p className='title-2'>Descrição</p>
                        <p className='subtitle-2'>{''}</p>
                    </div>
                    </>
                    )}
                  
                    </div>
                </ContainerDiv>
                <div>
                    {/* utilizar integration_id */}
                    <p className='autentic-p'>Autenticação</p>
                    <span className='autentic-span '>{transfer.integration_id}</span>
                </div>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <ContainerButtons>
                <ButtonDefault
                    title="Fechar"
                    background={theme.color.white}
                    color={theme.color.blackButton}
                    width="170px;"
                    onClick={handleCancel}
                />
                {transfer.id > 0 && (
                    <PDFDownloadLink
                        document={<DocumentTransfer transfer={transfer} account={account}/>}
                        fileName={`transfer_${transfer.protocol}.pdf`}
                        className="button-pdf"
                    >
                        {({blob, url, loading, error}) =>
                            loading ? (
                                'Loading document...'
                            ) : (
                                <>Baixar</>
                            )
                        }
                    </PDFDownloadLink>
                )}
            </ContainerButtons>
        </Modal.Footer>
    </Modal>)
}

export default TransferModal
