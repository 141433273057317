import Numeral from "numeral";
import moment from "moment";
Numeral.register("locale", "pt-br", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "mil",
    million: "milhões",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return "º";
  },
  currency: {
    symbol: "R$",
  },
});
Numeral.locale("pt-br");
export function formatToReal(value) {
  return Numeral(value).format("$ 0,0.00");
}

export const formatCnpj = (cnpj) => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const formatDate = (date) => {
  const currentDate = new Date(date);
  const transformedDate = moment(currentDate).format("DD/MM/YYYY");
  if (!transformedDate) return "";
  return transformedDate;
};

export const formatHour = (date) => {
  const currentDate = new Date(date);
  const transformedDate = moment(currentDate).format("hh:mm");
  if (!transformedDate) return "";
  return transformedDate;
};

export const formatDateHour = (date) => {
  const currentDate = new Date(date);
  const transformedDate = moment(currentDate).format("DD/MM/YYYY hh:mm:ss");
  if (!transformedDate) return "";
  return transformedDate;
};
