import React from 'react'
import { Container, ContainerItem, Label, Value } from './styles'
import PropTypes from 'prop-types'
import { formatToReal } from '../../../../utils/format'

const diplayValues = [
  { key: 'fantasy_name', name: 'Nome fantasia', value: '', position: 'row' },
  { key: 'legal_name', name: 'Nome legal', value: '', position: 'row' },
  { key: 'document', name: 'Cnpj', value: '', position: 'row' },
  { key: 'email', name: 'Email do usuário', value: '', position: 'row' },
  { key: 'name', name: 'Nome do usuário', value: '', position: 'row' },
  { key: 'zip_code', name: 'Cep', value: '', position: 'row' },
  { key: 'address', name: 'Logradouro', value: '', position: 'column' },
  { key: 'neighborhood', name: 'Bairro', value: '', position: 'column' },
  { key: 'city', name: 'Cidade', value: '', position: 'column' },
  { key: 'state', name: 'Estado', value: '', position: 'column' },
  { key: 'number', name: 'Número', value: '', position: 'column' },
  { key: 'complement', name: 'Complemento', value: '', position: 'column' },
]

function DisplayInfo({ item }) {
  return (
    <Container>
        <ContainerItem className="row">
        <Label>Email do usuário</Label>
        <Value>{item.user_email}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Nome do usuário</Label>
        <Value>{item.user_name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Documento do usuário</Label>
        <Value>{item.user_document}</Value>
      </ContainerItem>
      <br></br>
      <br></br>
      <br></br>
      <ContainerItem className="row">
        <Label>Nome fantasia</Label>
        <Value>{item.fantasy_name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Nome legal</Label>
        <Value>{item.legal_name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Cnpj</Label>
        <Value>{item.document}</Value>
      </ContainerItem>
    
      <ContainerItem className="row">
        <Label>Cep</Label>
        <Value>{item.zip_code}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Logradouro</Label>
        <Value>{item.address}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Bairro</Label>
        <Value>{item.neighborhood}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Cidade</Label>
        <Value>{item.city}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Estado</Label>
        <Value>{item.state}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Número</Label>
        <Value>{item.number}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Complemento</Label>
        <Value>{item.complement}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Porte</Label>
        <Value>{item.size}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Natureza Jurídica</Label>
        <Value>{item.legal_nature}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Atividade principal</Label>
        <Value>
          {item.main_activity.code} - {item.main_activity.text}
        </Value>
      </ContainerItem>

      <ContainerItem className="row">
        <Label>Atividades secundárias</Label>
        {item.secondary_activities.map((itemS) => (
          <>
            {' '}
            <Value>
              {itemS.code} - {itemS.text}
            </Value>
            <br></br>
          </>
        ))}
      </ContainerItem>

      <ContainerItem className="row">
        <Label>Sócios</Label>
        {item.partners.map((itemS) => (
          <>
            <Value>
              {itemS.nome} - {' '}
              {itemS.qual}{' '}
            </Value>
            <br></br>
          </>
        ))}
      </ContainerItem>
    </Container>
  )
}

DisplayInfo.propTypes = {
  items: PropTypes.array.isRequired,
}

export default DisplayInfo
