export function transactionsRequest({ per_page, page, query,order_by,callback }) {
  return {
    type: '@transaction/TRANSACTIONS_REQUEST',
    payload: { per_page, page, query,order_by,callback },
  }
}

export function transactionsRequestSuccess({ data, page, pages, total }) {
  return {
    type: '@transaction/TRANSACTIONS_REQUEST_SUCCESS',
    payload: { data, page, pages, total },
  }
}
