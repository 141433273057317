import React, { useState, useEffect, useCallback } from "react";
import { Container, Header } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { formatToReal } from "../../../utils/format";
import moment from "moment";
import PyamentIcon from "../../PyamentIcon"
import * as BillingActions from "../../../store/modules/billing/actions";
import * as GeneralActions from "../../../store/modules/general/actions";
import { setOnClipboard } from "../../../utils/clipboard";
import Paginator from "../../../components/Paginator";

function PendingChanges() {
  // const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(3);
  // const [pages, setPages] = useState(2);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { billings, page, pages, total } = useSelector(
    (state) => state.billing
  );
  const [hide, setHide] = useState(false);

  const selectColor = (status) => {
    switch (status) {
      case "create":
        return theme.color.green2;
      default:
        return theme.color.green;
    }
  };

  const handlePagePress = useCallback((page) => {
    dispatch(BillingActions.pendingBillingsRequest({ per_page: 10, page }));
  });

  const renderPaymentTypes = (payments) => {
    console.log((payments == ""))
    if (payments == "")
    {
      return ""
    }
   
    let paymentsParsed = JSON.parse(payments)
    return paymentsParsed.map((item, index) => (
      <PyamentIcon key={index} className="display-pay" type={item} size={20} textSize={0} />
    ))
  }

  const copylink = (link) => {
    dispatch(GeneralActions.setMessage({ message: "link copiado" }));
    setOnClipboard(link)

  }

  useEffect(() => {
    dispatch(BillingActions.pendingBillingsRequest({ per_page: 10, page: 1 }));
  }, [dispatch]);

  return (
    <Container>
      <div className="content">
        <Header onClick={() => setHide(!hide)}>
          <h1>Cobranças pendentes</h1>
          <i className="material-icons md-14">
            {hide ? "keyboard_arrow_down" : "keyboard_arrow_up"}
          </i>
        </Header>
        <p>Todas as cobrança que sofreram atualizações.</p>
        {!hide && (
          <>
            {!billings.length && <p>nenhuma cobrança pendente</p>}
            {!!billings.length && (
              <table>
                <thead>
                  <tr className="t-header">
                    <th>ID</th>
                    <th>Valor</th>
                    <th>Tipos</th>
                    <th>Pacela</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {billings.map((b) => (
                    <tr key={b.id}>
                      <td style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "5px",
                            backgroundColor: theme.color[b.status] || "#fff",
                            height: "100%",
                          }}
                        ></div>
                        {b.id}
                      </td>
                      <td>{formatToReal(b.total)}</td>
                      <td>
                        <div className="container-pay">
                          {renderPaymentTypes(b.payment_types)}
                        </div>
                      </td>
                      <td>{b.installment}/{b.installment_total}</td>
                      <td>
                        {/* <i className="material-icons md-14">info</i>
                        <i className="material-icons md-14">edit</i> */}
                        <i onClick={() => copylink(b.link_ref)} className="material-icons md-14" title="Copiar link">share</i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Paginator
              page={page}
              pages={pages}
              total={total}
              callback={handlePagePress}
            />
          </>
        )}
      </div>
    </Container>
  );
}

export default PendingChanges;
