import React, {useEffect, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import {ContainerPage} from "../../../../components/Cards/ClientCreate/styles";
import {ButtonDefault} from "../../../../components/Buttons";
import {useTheme} from "styled-components";
import {ContainerButtons} from "./styles";
import { useSelector} from "react-redux";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'

import {formatCnpj, formatDate, formatDateHour,formatToReal} from "../../../../utils/format";
import logoIcon from "../../../../assets/images/packman-white.png";


const PayBillModal = ({visible, onClose}) => {
  const theme = useTheme();

  const account = useSelector((state) => state.account.account)

  const { paybill } = useSelector((state) => state.paybill)

  const DocumentPayBill = ({ paybill, account }) => {
    return (
        <Document>
          <Page
              size="A4"
              style={{ paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 }}
          >
            <View>
              <Image
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: 'black',
                  }}
                  src={logoIcon}
              />
            </View>
            <Text style={{ fontSize: 24, textAlign: 'left', marginTop: 12 }}>
              Comprovante de Pagamento
            </Text>
            <Text style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}>
              {' '}
              Valor:{' '}
            </Text>
            <Text style={{ marginTop: 4, fontSize: 10 }}>
              {' '}
              {formatToReal(parseFloat(paybill.amount))}{' '}
            </Text>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Conta Origem:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    De:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.sender_name}{' '}
                  </Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Documento:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.sender_document}{' '}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Instituição:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.bank_name}{' '}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Favorecido:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    Para:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.name}{' '}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Emissor:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.assignor}{' '}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Vencimento:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.dueDate}{' '}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Linha digitável:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.bar_code}{' '}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View
                  style={{ borderBottom: '1px dashed black', paddingVertical: 4 }}
              >
                <Text style={{ marginTop: 12, fontWeight: '400', fontSize: 18 }}>
                  {' '}
                  Informações:{' '}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    Data:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {formatDateHour(paybill.updatedAt)}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexWrap: 'wrap' }}>
                <View>
                  <Text
                      style={{ marginTop: 12, fontWeight: 'bold', fontSize: 12 }}
                  >
                    {' '}
                    ID da transação:{' '}
                  </Text>
                  <Text style={{ marginTop: 4, fontSize: 10 }}>
                    {' '}
                    {paybill.integration_id}{' '}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
    )
  }


  const handleCancel = () => {
    onClose()
  };



  return (<Modal show={visible} onHide={onClose} size="md" centered>
    <Modal.Header closeButton>
      <Modal.Title>Pagamento de conta {paybill?.id}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        <ContainerPage>
          <div className="field">
            <label>Valor</label>
            <input
                type="text"
                placeholder="Valor"
                value={formatToReal(parseFloat(paybill.amount))}
                disabled
            ></input>
          </div>
          <div className="field">
            <label>Data</label>
            <input
                type="text"
                placeholder="Quantidade"
                value={formatDate(paybill.updatedAt)}
                disabled
            ></input>
          </div>
          <div className="field">
            <label>Nome da origem:</label>
            <input
                type="text"
                placeholder="Nome da Origem"
                value={paybill.sender_name}
                disabled
            ></input>
          </div>
          <div className="field">
            <label>Documento</label>
            <input
                type="text"
                placeholder="Valor"
                value={paybill.sender_document}
                disabled
            ></input>

          </div>
          <div className="field">
            <label>Instituição</label>
            <input
                type="text"
                placeholder="Valor"
                value={paybill.bank_name}
                disabled
            ></input>

          </div>
          <br></br>
          <div className="field">
            <label>Favorecido</label>
            <input
                type="text"
                value={paybill.name}
                disabled
            ></input>
          </div>
          <div className="field">
            <label>Emisor</label>
            <input
                type="text"
                value={paybill.assignor}
                disabled
            ></input>
          </div>

          <div className="field">
            <label>Data de Vencimento</label>
            <input
                type="text"
                value={paybill.dueDate}
                disabled
            ></input>
          </div>
          <div className="field">
            <label>Linha digitavel</label>
            <input
                type="text"
                value={paybill.bar_code}
                disabled
            ></input>
          </div>
        </ContainerPage>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <ContainerButtons>
        <ButtonDefault
            title="Fechar"
            background={theme.color.white}
            color={theme.color.blackButton}
            width="170px;"
            onClick={handleCancel}
        />
        {paybill.integration_id != "" && (
            <PDFDownloadLink
                document={<DocumentPayBill paybill={paybill} account={account} />}
                fileName={`deposit_${paybill.integration_id}.pdf`}
                className="button-pdf"
            >
              {({ blob, url, loading, error }) =>
                  loading ? (
                      'Loading document...'
                  ) : (
                      <>Baixar</>
                  )
              }
            </PDFDownloadLink>
        )}
      </ContainerButtons>
    </Modal.Footer>
  </Modal>)
}

export default PayBillModal
