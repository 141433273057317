import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  myclient: {},
  per_page: 10,
  totalResume: {
    total: 0.0,
    total_paid: 0.0,
    total_expired: 0.0,
    note: 0.0,
  },
  company: {},
}

export default function MyClient(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@myclient/LIST_REQUEST_SUCCESS':
      return produce(state, (draft) => {
        draft.data = action.payload.data
        draft.page = action.payload.page
        draft.pages = action.payload.pages
        draft.total = action.payload.total
      })
    case '@myclient/DEFINE_DETAIL':
      return produce(state, (draft) => {
        draft.myclient = action.payload.myclient
      })
    case '@company/DEFINE_DETAIL':
      return produce(state, (draft) => {
        draft.company = action.payload.company
      })
    case '@myclient/RESUME_BILLING_SUCCESS':
      return produce(state, (draft) => {
        draft.totalResume = action.payload.totalResume
      })
    default:
      return state
  }
}
