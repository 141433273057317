import { all, takeLatest, put, call } from 'redux-saga/effects'

import getError from '../../../services/errors'
import * as UserActions from '../user/actions'
import { setError } from './actions'

import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export function* getErrorAndResolve(action) {
  const error = action.payload
  let code = error.error.response?.data?.code || '0'
  console.log(action.payload)
  switch (code) {
    case '10035':
    case '10013':
      yield put(UserActions.setToken(''))
      window.location.pathname = '/'
      break
    // case '10050':
    //   history.push('/investor/register/confirm')
    //   break
    default:
      break
  }

  if (code != '10013') {
    const errorMessage = getError('PT_BR', code)

    MySwal.fire({
      html: `<p>${errorMessage}</p>`,
      icon: 'error',
      title: 'Houve um erro',
    })
    yield put(setError(errorMessage))
  }
}

//

export default all([
  takeLatest('@general/CHECK_ERROR_AND_RESOLVE', getErrorAndResolve),
])
