import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { Container, Break, ContainerItem, Item } from "./styles";

const types = ["Pagamentos", "Transporte"];

const payments = [
  {
    name: "Nota fiscal",
    icon: 'trending_down',
    iconType: "description",
    href: "/gestao/notafiscal"
  },
  {
    name: "Funcionários",
    icon: 'group',
    iconType: "description",
    href: "/gestao/funcionario"
  },
]

export default function CRM() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {

  }, [dispatch]);

  return (
    <Container>
      <h1>Gestão</h1>
      <div className="horizontal">
        <p>Gestão total da sua empresa</p>
      </div>
      <ContainerItem>
        {
          payments.map(item => (
            <Item onClick={() => history.push(item.href)}>
              <div className="title">{item.name}</div>
              <div className="icon">
                <i className="material-icons">{item.icon}</i>
              </div>
            </Item>
          )
          )
        }
      </ContainerItem>
    </Container>
  );
}
