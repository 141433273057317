import React from 'react'
import { Container, ContainerItem, Label, Value } from './styles'
import PropTypes from 'prop-types'
import { formatToReal } from '../../../../utils/format'

function DisplayInfo({ item }) {
  return (
    <Container>
      <ContainerItem className="row">
        <Label>Nome fantasia</Label>
        <Value>{item.fantasy_name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Nome legal</Label>
        <Value>{item.name}</Value>
      </ContainerItem>
      <ContainerItem className="row">
        <Label>Cnpj</Label>
        <Value>{item.document}</Value>
      </ContainerItem>

      <ContainerItem className="row">
        <Label>Cep</Label>
        <Value>{item.zip_code}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Logradouro</Label>
        <Value>{item.address_line}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Bairro</Label>
        <Value>{item.neighborhood}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Cidade</Label>
        <Value>{item.city}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Estado</Label>
        <Value>{item.state}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Número</Label>
        <Value>{item.building_number}</Value>
      </ContainerItem>
      <ContainerItem className="column">
        <Label>Complemento</Label>
        <Value>{item.complement}</Value>
      </ContainerItem>
    </Container>
  )
}

DisplayInfo.propTypes = {
  items: PropTypes.array.isRequired,
}

export default DisplayInfo
