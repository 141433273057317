import React, { useMemo, useState } from 'react'
import {
  ContainerWrapper,
  ContainerChildren,
  Scroll,
  ContainerMenu,
  List,
  ListItem,
  Header,
  ImageContainer,
  ContainerMenuBottom,
  ListItemBottom,
  ListBottom
} from './styles'
import { HeaderUserInformations } from '../../components/Headers'
import { useHistory } from 'react-router-dom'
import logoIcon from '../../assets/logo/icon.svg'

function Menu({ children }) {
  const { pathname } = useHistory().location
  const [hide, setHide] = useState(false)
  return (
    <ContainerWrapper>
      <ContainerMenu>
        <ImageContainer
          onClick={() => {
            setHide(!hide)
          }}
        >
          <img className="logo" src={logoIcon} alt="icone banco genesis" />
        </ImageContainer>

        <List className={hide ? 'hide' : ''}>
          <ListItem current={+pathname.includes('/home')} to="/home">
            <div className="flag-marked"></div>
            <i className="material-icons">home</i>
            <p>home</p>
          </ListItem>
          <ListItem current={+pathname.includes('/crm')} to="/crm">
            <div className="flag-marked"></div>
            <i className="material-icons">recent_actors</i>
            <p>CRM</p>
          </ListItem>
          {/* <ListItem
            current={+pathname.includes('/conveniencia')}
            to="/conveniencia"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">store</i>
            <p>conveniência</p>
          </ListItem> */}
          <ListItem
            current={+pathname.includes('/financeiro')}
            to="/financeiro"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">assessment</i>
            <p>Financeiro</p>
          </ListItem>
{/* 
          <ListItem
            current={+pathname.includes('/financeiro/movimentacao')}
            to="/financeiro/movimentacao"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">sync_alt</i>
            <p>Extrato</p>
          </ListItem> */}
          <ListItem current={+pathname.includes("/gestao")} to="/gestao">
            <div className="flag-marked"></div>
            <i className="material-icons">assignment</i>
            <p>Gestão</p>
          </ListItem>
        </List>
      </ContainerMenu>
      <ContainerChildren>
        <HeaderUserInformations />
        <Scroll>{children}</Scroll>
      </ContainerChildren>
      <ContainerMenuBottom>
        <ListBottom className={hide ? 'hide' : ''}>
          <ListItemBottom current={+pathname.includes('/home')} to="/home">
            <div className="flag-marked"></div>
            <i className="material-icons">home</i>
            <p>home</p>
          </ListItemBottom>
          <ListItemBottom current={+pathname.includes('/crm')} to="/crm">
            <div className="flag-marked"></div>
            <i className="material-icons">recent_actors</i>
            <p>CRM</p>
          </ListItemBottom>
          <ListItemBottom
            current={+pathname.includes('/user/changepass')}
            to="/user/changepass"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">account_circle</i>
            <p>Meu Perfil</p>
          </ListItemBottom>
          <ListItemBottom
            current={+pathname.includes('/financeiro')}
            to="/financeiro"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">assessment</i>
            <p>Financeiro</p>
          </ListItemBottom>
          {/* <ListItemBottom
            current={+pathname.includes('/financeiro/movimentacao')}
            to="/financeiro/movimentacao"
          >
            <div className="flag-marked"></div>
            <i className="material-icons">sync_alt</i>
            <p>Extrato</p>
          </ListItemBottom> */}
        </ListBottom>
      </ContainerMenuBottom>
    </ContainerWrapper>
  )
}

export default Menu
