import produce from "immer";

const INITIAL_STATE = {
  deposit: {},
  data: [],
  page: 1,
  pages: 1,
  total: 0,
  successPaid: false,
  per_page:3,
};

export default function Deposit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@deposit/DEPOSITS_REQUEST_SUCCESS":
      return produce(state, (draft) => {
        draft.data = action.payload.data;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case "@deposit/NEW_DEPOSIT_SUCCESS":
      return produce(state, (draft) => {
        console.log(action.payload);
        draft.deposit = action.payload.deposit;
      });
    case "@deposit/GET_DEPOSIT_SUCCESS":
      return produce(state, (draft) => {
        draft.deposit = action.payload.deposit;
        draft.successPaid = action.payload.deposit.status == "done";
        if (action.payload.deposit.status == "done") {
          clearInterval(window.checkStatus);
        }
      });
    case "@deposit/DEFINE_DETAIL":
      return produce(state, (draft) => {
        draft.deposit = action.payload.deposit;
      });
    default:
      return state;
  }
}
