import React from "react";
import { Switch, Redirect, Route, Router } from "react-router-dom";

import history from "../services/history";

import PrivateRoute from "./PrivateRoute";

import Menu from "../layout/Menu";


//PUBLIC
import Welcome from "../pages/Welcome";
import Login from "../pages/Login";
import Forget from "../pages/Forget";
import RegisterUser from "../pages/Register/User";
import NotFound from "../pages/_404";

//PRIVATE

import ChangePassword from "../pages/User/ChangePassword";
import Home from "../pages/Home";
import Billing from "../pages/Finance/Billing";
import NewBilling from "../pages/Finance/Billing/New";
import Transfer from "../pages/Finance/Transfer";
import NewTransfer from "../pages/Finance/Transfer/New";
import ApproveTransfer from "../pages/Finance/Transfer/Approve";
import Apikey from "../pages/APIKey";
import NewApiKey from "../pages/APIKey/New";
import RegisterCompany from "../pages/Register/Company";
import RegisterAddress from "../pages/Register/Address";

import RegisterDocumentPersonal from "../pages/Register/DocumentPersonal";
import RegisteDocumentCompany from "../pages/Register/DocumentCompany";
import RegisteLegalRepresentative from "../pages/Register/LegalRepresentative";

import ConfirmationScreen from "../pages/Register/Confirmation";
import RegisterCode from "../pages/Register/Code";
import CRM from "../pages/CRM";
import Deposit from "../pages/Finance/Deposit";
import NewDeposit from "../pages/Finance/Deposit/New";
import Transaction from "../pages/Finance/Transaction";
import Supplier from "../pages/CRM/Supplier";
import SupplierNew from "../pages/CRM/SupplierNew";
import SupplierDetail from "../pages/CRM/SupplierDetail";
import SupplierAccount from "../pages/CRM/SupplierAccount";

import InviteNew from "../pages/CRM/InviteNew";

import Invite from "../pages/CRM/Invite";
import Company from "../pages/CRM/Company";
import CompanyDetail from "../pages/CRM/CompanyDetail";

import Client from "../pages/CRM/Client";
import ClientNew from "../pages/CRM/ClientNew";
import ClientDetail from "../pages/CRM/ClientDetail";

import Paybill from "../pages/Paybill";
import NewPaybill from "../pages/Paybill/New";

import Convenience from "../pages/Convenience";

import Finance from "../pages/Finance";

import Manager from "../pages/Manager";
import Invoice from "../pages/Manager/Invoice";
import Employee from "../pages/Manager/Employee";
import NewEmployee from "../pages/Manager/EmployeeNew";

import Pix from "../pages/Finance/Pix";
import NewPix from "../pages/Finance/Pix/New";








export default function Routes() {
  const routes = [
    
    {
      path: "home",
      component: Home,
    },
    {
      path: "user/changepass",
      component: ChangePassword,
    },
    {
      path: "api/novo",
      component: NewApiKey,
    },
    {
      path: "api",
      component: Apikey,
    },
    {
      path: "crm",
      component: CRM,
    },
    {
      path: "conveniencia",
      component: Convenience,
    },
    {
      path: "financeiro",
      component: Finance,
    },
    {
      path: "financeiro/deposito",
      component: Deposit,
    },
    {
      path: "financeiro/deposito/novo",
      component: NewDeposit,
    },
    {
      path: "financeiro/pix",
      component: Pix,
    },
    {
      path: "financeiro/pix/novo",
      component: NewPix,
    },

    {
      path: "financeiro/transferencia",
      component: Transfer,
    },
    {
      path: "financeiro/transferencia/novo",
      component: NewTransfer,
    },

    {
      path: "financeiro/transferencia/approve/:id",
      component: ApproveTransfer,
    },

    {
      path: "financeiro/cobranca",
      component: Billing,
    },
    {
      path: "financeiro/cobranca/novo",
      component: NewBilling,
    },

    {
      path: "financeiro/movimentacao",
      component: Transaction,
    },

    {
      path: "crm/fornecedor",
      component: Supplier,
    },

    {
      path: "crm/cliente",
      component: Client,
    },


    {
      path: "crm/sub-conta",
      component: Company,
    },

    {
      path: "crm/sub-conta/detalhe/:id",
      component: CompanyDetail,
    },

    {
      path: "crm/convite",
      component: Invite,
    },

    {
      path: "crm/convite/novo",
      component: InviteNew,
    },

    



    {
      path: "gestao",
      component: Manager,
    },
    {
      path: "gestao/notafiscal",
      component: Invoice,
    },
    {
      path: "gestao/funcionario",
      component: Employee,
    },
    {
      path: "gestao/funcionario/novo",
      component: NewEmployee,
    },
    {
      path: "conveniencia/pagar-conta",
      component: Paybill,
    },
    {
      path: "conveniencia/pagar-conta/novo",
      component: NewPaybill,
    },
    {
      path: "crm/cliente/detalhe/:id",
      component: ClientDetail,
    },
    {
      path: "crm/cliente/new",
      component: ClientNew,
    },
    {
      path: "crm/fornecedor",
      component: Supplier,
    },
    {
      path: "crm/fornecedor/new",
      component: SupplierNew,
    },
    {
      path: "crm/fornecedor/detalhe/:id",
      component: SupplierDetail,
    },
  ];

  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Welcome} />
        <Route path="/login" exact component={Login} />
        <Route path="/forget" exact component={Forget} />
        <Route path="/cadastro/usuario" exact component={RegisterUser} />
        <Route
          path="/cadastro/representante"
          exact
          component={RegisteLegalRepresentative}
        />
        <PrivateRoute
          path="/cadastro/empresa"
          exact
          component={RegisterCompany}
        />
        <PrivateRoute
          path="/cadastro/endereco"
          exact
          component={RegisterAddress}
        />
        <PrivateRoute
          path="/cadastro/documento/pessoal"
          exact
          component={RegisterDocumentPersonal}
        />
        <PrivateRoute
          path="/cadastro/documento/empresa"
          exact
          component={RegisteDocumentCompany}
        />
        <PrivateRoute
          path="/cadastro/confirmacao"
          exact
          component={ConfirmationScreen}
        />
        <Route path="/cadastro/codigo" exact component={RegisterCode} />
        {routes.map((R) => (
          <PrivateRoute
            key={R.path}
            path={`/${R.path}`}
            exact
            component={() => (
              <Menu>
                <R.component />
              </Menu>
            )}
          />
        ))}
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}
