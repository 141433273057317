import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  TransactionItem,
} from './styles'
import {
  formatToReal,
  formatDate,
  formatDateHour,
  formatCnpj,
  formatHour,
} from '../../../utils/format'
import '../../../assets/animations/animations.css'
import apiPayment from '../../../services/apiPayment'


export default function Item({index,item,handleInfoRightMenu}) {

  const [balance, setBalance] = useState(0)

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    setBalance(0)
    apiPayment
        .get(`/payment/manager/v1/web/balance/${item.id}/partner`)
        .then(({ data }) => {
          setBalance(data.balance)
        })
  }, [item])

  return (
      <>
        <TransactionItem key={index}>
          <div className="bar-container">
            <div
                className="bar"
                style={{
                  backgroundColor:
                      item.step_register == 1
                          ? theme.color['in']
                          : theme.color['out'],
                }}
            ></div>
          </div>
          <div className="info">
            <span className="title">{item.name}</span>
            <span className="subtitle">{item.email}</span>
            <span className="subtitle">
                          {' '}
              {formatCnpj(item.document)}
                        </span>
            <span className="subtitle" style={{fontWeight:"bold"}}>
                          {' '}
              {formatToReal(parseFloat(balance))}
                        </span>

          </div>


          <div className={`price`}>
            {' '}
            <span className="clock">
                          {' '}
              {formatDate(item.createdAt)}{' '}
                        </span>
          </div>
          <div
              className="action"
              onClick={() => {
                handleInfoRightMenu(item)
              }}
          >
            <i className="material-icons icon">navigate_next</i>
          </div>
        </TransactionItem>
      </>
  )
}
