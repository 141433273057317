const colors = {
  blackButton: "#272626",
  blackText: "#434544",
  blackTextWelcome: "#585858",
  gray: "#707070",
  white: "#FFFFFF",
  home: "#EFEFEF",
  background_card_info: "#F2F6FB",
  grayText: "#747474",
  green: "#07EA93",
  text: "#404040",
  red: "#D14848",
  greenValue: "#68F4A8"
};

export default colors;
