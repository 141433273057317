import axios from "axios";

import apiPayment from './apiPayment'

const url = process.env.REACT_APP_RECEITA_URL;

const apiCNPJ = {


  checkCNPJ: (cnpj) => {
    return apiPayment
      .get(`/manager/v1/cnpj/${cnpj}`)
      .then((result) => {
        return result.data
      })
  },
};

export default apiCNPJ;
