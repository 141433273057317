import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { ButtonDefault } from "../../../components/Buttons";
import { Container } from "./styles";
import { formatToReal } from "../../../utils/format";
import * as NfActions from "../../../store/modules/nf/actions";
import Paginator from "../../../components/Paginator";

import PyamentIcon from "../../../components/PyamentIcon"



export default function Invoice() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, page, pages, total } = useSelector(
    (state) => state.nf
  );

  useEffect(() => {
    dispatch(NfActions.nfRequest({ per_page: 10, page: 1 }));
  }, [dispatch]);

  const handlePagePress = useCallback((page) => {
    dispatch(NfActions.nfRequest({ per_page: 10, page }));
  });

  const cancel = (item) => dispatch(NfActions.CancelNf(item.id));

  const handleNewPress = () => {
    clearInterval(window.checkStatus)
    history.push("/gestao/invoice/novo");
  };

  return (
    <Container>
      <h1>Nota fiscais</h1>
      <div className="horizontal">
        <p>Lista de notas fiscais</p>
        <ButtonDefault
          type="button"
          title="Novo"
          background={theme.color.green}
          color={theme.color.white}
          width="250px"
          height="50px"
          onClick={handleNewPress}
        />
      </div>
      {!data.length && <p>nenhuma nota fiscal realizado</p>}
      {!!data.length && (
        <>
          <table>
            <thead>
              <tr className="t-header">
                <th>ID</th>
                <th>Valor</th>
                <th>Status</th>
                <th>Link</th>
                <th>Link de cancelamento</th>
                <th>Criado em:</th>
                <th>Açoes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((b) => (
                <tr key={b.id} className={`${b.status}`} >
                  <td style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "5px",
                        backgroundColor: theme.color[b.status] || "#fff",
                        height: "100%",
                      }}
                    ></div>
                    {b.id}
                  </td>
                  <td>{formatToReal(b.amount)}</td>
                  <td>{b.status}</td>
                  <td>{b.pdf_link}</td>
                  <td>{b.pdf_link_cancel}</td>
                  <td>{b.canceled_at}</td>
                  <td className="actions">
                    <div className="button">
                      <i className="fas fa-exclamation-circle"></i>
                    </div>
                    <div className="button">
                      <i className="fas fa-pen-square"></i>
                    </div>
                    <div className="button" onClick={() => cancel(b)}>
                      <i className="fas fa-trash"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            page={page}
            pages={pages}
            total={total}
            callback={handlePagePress}
          />
        </>
      )}
    </Container>
  );
}
